
import "../css/shortcode.css"
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { RiDoubleQuotesL } from "react-icons/ri";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import choutelajson from "../components/choutelajson";
import { Link } from "react-router-dom";
// import required modules
import { Navigation } from 'swiper/modules';
import { MetaTags } from "react-meta-tags";
const Home = () => {
  return (
    
    <div>
      <MetaTags>
      <title>Tax Consultant In Dombivli | Choutela & Associates</title>
        <meta title="Tax Consultant In Dombivli | Choutela & Associates" />
        <meta
          name="description"
          content="Looking for a reliable tax consultant in Dombivli? Our experienced team provides expert tax advisory services, ensuring compliance and maximizing your financial efficiency. Trust us for personalized solutions tailored to your needs. Contact us for professional assistance with tax planning, filing, and optimization in Dombivli."
        />
        <meta
          name="keywords"
          content="tax consultant in dombivli"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Tax Consultant In Dombivli | Choutela & Associates" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Looking for a reliable tax consultant in Dombivli? Our experienced team provides expert tax advisory services, ensuring compliance and maximizing your financial efficiency. Trust us for personalized solutions tailored to your needs. Contact us for professional assistance with tax planning, filing, and optimization in Dombivli." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
         <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
      
<SwiperSlide>    <section
  className="banner p-0 position-relative fullscreen-banner"
  style={{ height: 730 }}
>
  <div
    className="banner-slider owl-carousel no-pb owl-loaded owl-drag"
    data-dots="false"
    data-nav="true"
  >
    <div className="owl-stage-outer">
      <div
        className="owl-stage"
        style={{
         
          transition: "all 0s ease 0s",
        width:"100%"
        }}
        
      >
       
        
        <div className="owl-item cloned" style={{ width: "100%" }}>
          <div
            className="item"
            data-bg-img="https://krishnataxconsultancy.com/images/bg/05.jpg"
            style={{ backgroundImage: 'url("https://krishnataxconsultancy.com/images/bg/05.jpg")' }}
          >
            <div className="align-center p-0" style={{ paddingTop: "181.1px" }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12">
                  <h1 className="mb-4 animated1">
              One Stop Solutions to All your Financial, 
              <span className="text-theme" style={{color:"#a72682"}}>Accounting & Taxation Needs....</span>
            </h1>
                    <p className="lead text-black animated2" />
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </div>
   
   
  </div>
</section></SwiperSlide>
<SwiperSlide>    <section
  className="banner p-0 position-relative fullscreen-banner"
  style={{ height: 730 }}
>
  <div
    className="banner-slider owl-carousel no-pb owl-loaded owl-drag"
    data-dots="false"
    data-nav="true"
  >
    <div className="owl-stage-outer">
      <div
        className="owl-stage"
        style={{
         
          transition: "all 0s ease 0s",
        width:"100%"
        }}
        
      >
       
        
        <div className="owl-item cloned" style={{ width: "100%" }}>
          <div
            className="item"
            data-bg-img="https://krishnataxconsultancy.com/images/bg/05.jpg"
            style={{ backgroundImage: 'url("https://krishnataxconsultancy.com/images/bg/05.jpg")' }}
          >
            <div className="align-center p-0" style={{ paddingTop: "181.1px" }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12">
                   <h1 className="mb-4 animated1">
              One Stop Solutions to All your Financial, 
              <span className="text-theme" style={{color:"#a72682"}}>Accounting & Taxation Needs....</span>
            </h1>
                    <p className="lead text-black animated2" />
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </div>
   
   
  </div>
</section></SwiperSlide>
      </Swiper>
  
        

      <section>
  <div className="container">
    <div className="row justify-content-center text-center">
      <div className="col-lg-8 col-md-12">
        <div className="section-title">
          <h1 className="title h3title">Choutela And Associates Tax Consultancy Services</h1>
          <p>
           
Choutela And Associates Tax Consultancy, a trusted name with a decade of expertise, excels in navigating the complexities of tax matters. Our seasoned professionals provide tailored solutions, ensuring optimal financial outcomes. With a proven track record, we are committed to delivering excellence, making us your reliable partner in tax consultancy.
          </p>
        </div>
      </div>
    </div>
    <div className="row">
 
      
      {choutelajson.map((ele) => {
                              const { title, slug,img } = ele;
                              return (
                                <div className="col-lg-4 col-md-4 mt-5">
                                <div
                                  className="featured-item services  text-center"
                                  data-bg-color="rgba(253,91,3,0.070)"
                                  style={{ backgroundColor: "rgba(253, 91, 3, 0.07)" }}
                                >
                                  <img src={img} width={325} height={200} />
                                  <div className="featured-title">
                                    <h5 style={{ textAlign: "center", marginTop: 10 }}>{title}</h5>
                                  </div>
                                  <div className="featured-desc">
                                    <a className="btn btn-2 gst" >
                                      {" "}
                                      <span className="btn-icon btn-arrow" />
                                     
                                      <p className="btn-text">  <Link to={slug}>Read More</Link></p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              );
                            })}
      
    </div>
  </div>
</section>
<div className="container">
  <div className="row">
    <div className="col-12">
      <div className="lined">
        <h3>testimonial</h3>
        <span className="bolded-line" />
      </div>
      <section
        className="dark-bg text-white parallaxie"
        data-bg-img="images/bg/07.jpg"
        data-overlay={8}
        style={{
          backgroundImage: 'url("images/bg/07.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center -65.76px"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="owl-carousel no-pb owl-loaded owl-drag"
                data-dots="false"
                data-nav="true"
                data-items={1}
                data-sm-items={1}
                data-autoplay="true"
              >
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      // transform: "translate3d(-4039px, 0px, 0px)",
                      transition: "all 0.25s ease 0s",
                      width: "100%"
                    }}
                  >
                    <div
                      className="owl-item cloned"
                      style={{ width: "100%" }}
                    >
                      <div className="item">
                        <div className="row text-center">
                          <div className="col-12">
                            <div className="testimonial style-2">
                              <div className="testimonial-img">
                                <img
                                  className="img-fluid d-inline"
                                  src="https://krishnataxconsultancy.com/images/testimonial/small/01.jpg"
                                  alt=""
                                />
                                <div className="testimonial-quote">
                                <RiDoubleQuotesL />
                                </div>
                              </div>
                              <div className="testimonial-content">
                                <p>
                                In the realm of tax complexities, the team at Choutela And Associates stands as a beacon of clarity and proficiency. Just as the passage denounces the pitfalls of being beguiled and demoralized, I found solace in their unwavering dedication to navigating the intricate landscape of tax regulations.
                                </p>
                                <div className="testimonial-caption">
                                  <h5>Rina Singh</h5>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              
       
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
   
  </div>
 
</div>
<div style={{padding:"30px"}}>

</div>

<div className="secondary-footer">
  <div className="container">
    <div className="copyright">
      <div className="row">
        <div className="col-lg-12" style={{textAlign:"center"}}>
          {" "}
          <span>
            Copyright 2024 Choutela And Associates tax consultancy Pvt Ltd 
          </span>
        </div>
        
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Home
