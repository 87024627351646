import React from 'react'
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6"; 
const Contectus = () => {
  return (
    <div>
      <section
  className="page-title parallaxie"
  data-bg-img="https://krishnataxconsultancy.com/images/bg/08.jpg"
  style={{
    backgroundImage: 'url("https://krishnataxconsultancy.com/images/bg/08.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center 108.66px"
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-12">
        <h1>Contact Us</h1>
        <nav aria-label="breadcrumb" className="page-breadcrumb">
          {/* <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.php">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contactus
            </li>
          </ol> */}
        </nav>
      </div>
    </div>
  </div>
</section>
<section>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="lined">
          <h3>Contact Us</h3>
          <span className="bolded-line" />
        </div>
      </div>
      <div className="col-md-12">
        <div className="contact-main white-bg p-3 box-shadow">
          <form className="row" method="post" action="#">
            <div className="form-group col-md-4">
              <input
                id="form_name"
                type="text"
                name="firstname"
                className="form-control"
                placeholder="First Name"
                required="required"
                data-error="Name is required."
              />
              <div className="help-block with-errors" />
            </div>
            <div className="form-group col-md-4">
              <input
                id="form_name1"
                type="text"
                name="lastname"
                className="form-control"
                placeholder="Last Name"
                required="required"
                data-error="Name is required."
              />
              <div className="help-block with-errors" />
            </div>
            <div className="form-group col-md-4">
              <input
                id="form_email"
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                required="required"
                data-error="Valid email is required."
              />
              <div className="help-block with-errors" />
            </div>
            <div className="form-group col-md-4">
              <input
                id="form_phone"
                type="tel"
                name="phone"
                className="form-control"
                placeholder="Phone"
                required="required"
                data-error="Phone is required"
              />
              <div className="help-block with-errors" />
            </div>
            <div className="form-group col-md-4">
            <input
                id="form_phone"
                type="tel"
                name="phone"
                className="form-control"
                placeholder="Phone"
                required="required"
                data-error="Phone is required"
              />
            
            </div>
            <div className="form-group col-md-4">
              <input
                id="form_subject"
                name="subject"
                className="form-control"
                placeholder="Subject"
                required="required"
                data-error="Subject is required"
              />
              <div className="help-block with-errors" />
            </div>
            <div className="form-group col-md-6">
              <textarea
                id="form_message"
                name="messages"
                className="form-control"
                placeholder="Message"
                rows={2}
                required="required"
                data-error="Please,leave us a message."
                defaultValue={""}
              />
              <div className="help-block with-errors" />
            </div>
            <div className="col-md-6 text-center mt-4">
              <button className="btn" name="submit" type="submit">
                <span>Send Messages</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="contact-info">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-12">
        <div className="contact-media text-center">
          {" "}
          <FaMapMarkerAlt  className='flatsize'/>
          <h4 className="text-theme text-center">Address</h4>
        </div>
        <p>
        Office No. 03 City Commercial Center, M.G Road, Opp. Vihar Hotel, Vishnu Nagar, Dombivli West, Dombivli, Maharashtra - 421202
        </p>
      </div>
      <div className="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div className="contact-media text-center">
          {" "}
          <IoMail className='flatsize' />
          <h4 className="text-theme text-center">Email Us</h4>
        </div>
        <p>choutelaandassociates@gmail.com</p>
        <p>bchoutela@gmail.com </p>
      </div>
      <div className="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div className="contact-media text-center">
          {" "}
         <FaPhone  className='flatsize'/>
          <h4 className="text-theme text-center">Call Us</h4>{" "}
        </div>
       
        <p>Proprietor : +91 9892844895 / +91 8077794482 / +91 9892744895 </p>
       
      </div>
    </div>
  </div>
</section>
<div className="map iframe-h-2">
  <iframe
   src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3767.3908314857113!2d73.0845921997846!3d19.221791364026227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be795c63013ba1f%3A0x6f47fee915bc1bb8!2sChoutela%20%26%20Associates!5e0!3m2!1sen!2sin!4v1705652236207!5m2!1sen!2sin"
    width={600}
    height={450}
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  />
</div>
<div className="secondary-footer">
  <div className="container">
    <div className="copyright">
      <div className="row">
        <div className="col-lg-12" style={{textAlign:"center"}}>
          {" "}
          <span>
            Copyright 2024 Choutela And Associates tax consultancy Pvt Ltd 
          </span>
        </div>
        
      </div>
    </div>
  </div>
</div>


    </div>
  )
}
 

export default Contectus
