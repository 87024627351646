
import React,{ useRef, useLayoutEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Collapse } from 'antd';
import { useLocation } from "react-router-dom";
import choutelajson from '../components/choutelajson';
import gstjson from "../components/gstjson"
import Registration from '../components/Registration';
const { Panel } = Collapse;
const GstDetails = () => {
  const search = useLocation();
  const Path = search.pathname;


const [edata, setEdata] = useState(Registration)
// const [Fdata,setFdata] = useState([])


const EventFdata = edata.find(e => e.slug === Path)
// setFdata(EventFdata);

console.log(EventFdata)
  return (
    <div>
        <section
  className="page-title parallaxie"
  data-bg-img="https://krishnataxconsultancy.com/images/bg/08.jpg"
  style={{
    backgroundImage: 'url("https://krishnataxconsultancy.com/images/bg/08.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center 108.66px"
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-12">
        {/* <h1> {EventFdata.title}</h1> */}

        <nav aria-label="breadcrumb" className="page-breadcrumb">
          <ol className="breadcrumb">
            {/* <li className="breadcrumb-item">
              <a href="index1.php">Home</a>
            </li> */}
            <li className="breadcrumb-item">
              <a href="#">{EventFdata.title1}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-12 col-12 mt-5 mt-lg-0">
        <div className="lined">
          <h3 style={{textTransform:"uppercase"}}x>WHAT IS MEANT BY {EventFdata.title}</h3>
          <span className="bolded-line" />
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <img
          className="img-fluid rounded"
          src={EventFdata.img}
          alt=""
        />
      </div>
      <div className="col-lg-6 col-12 mt-5 mt-lg-0">
        <div className="section-title">
          <p>
            {EventFdata.maincontent}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
  
<section>
  <div className='container'>

  
  <div className="container conatiner">
    <div className="row">
      <div className="col-lg-12 col-12 mt-5 mt-lg-0">
        <div className="lined">
          <h4>{EventFdata.title}</h4>
          <span className="bolded-line" />
        </div>
      </div>
      <h5>{EventFdata.documentrequire}</h5>
      <div className="col-lg-6">
        <div className="row">
        <Collapse defaultActiveKey={['0']}>
        {EventFdata.accorden.map((ele) => {
                      const { sectitle, id } = ele
                      if (id !== 0) {
                        return (
                          <Panel header={ele.sectitle} key={ele.id}>
                             <p>
                            <p>
             
             {ele.secdecription}
             <ul>

               <h6>{ele.h61}</h6>
               <p>{ele.ul1}</p>
               <h6>{ele.h62}</h6>
               <p>{ele.ul2}</p>
               <h6>{ele.h63}</h6>
               <p>{ele.ul3}</p>
               <h6>{ele.h64}</h6>
               <p>{ele.ul4}</p>
               <h6>{ele.h65}</h6>
               <p>{ele.ul5}</p>
               <h6>{ele.h66}</h6>
               <p>{ele.ul6}</p>
               <h6>{ele.h67}</h6>
               <p>{ele.ul7}</p>
               <p>{ele.ul8}</p>
               <p>{ele.ul9}</p>
               <p>{ele.ul10}</p>
               <p>{ele.ul11}</p>
               <p>{ele.ul12}</p>
               <p>{ele.ul13}</p>
               <p>{ele.ul14}</p>


               <ol>
                 <p>{ele.ol1}</p>
                 <p>{ele.ol2}</p>
               </ol>
        


             </ul>
             </p>
                             
                            </p>
                          </Panel>
                        )
                      }
                      return null; // If id is 0, return null (skip this iteration)
                    })}
                </Collapse>

        </div>
      </div>
      <div
        className="col-lg-6 mt-lg-0"
        style={{
          height: "100%",
          boxShadow: "2px #000",
          border: "1px #808080 solid",
          borderRadius: 10,
          padding: 20
        }}
      >
        <h5>{EventFdata.title} Form</h5>
        <form method="post" role="form">
          <div className="row">
            <div className="col-md-6 mb-2 pb-2">
              <label className="labelsize">Full Name</label>
              <input
                type="text"
                name="fullname"
                className="form-control"
                required="required"
                data-error="Name is required."
              />
            </div>
            <div className="col-md-6 mb-2 pb-2">
              <label className="labelsize">Email ID</label>
              <input
                type="email"
                name="emailid"
                className="form-control"
                required="required"
                data-error="Name is required."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2 pb-2">
              <label className="labelsize">State</label>
              <input
                type="text"
                name="fullname"
                className="form-control"
                required="required"
                data-error="Name is required."
              />
              
            </div>
            <div className="col-md-6 mb-2 pb-2">
              <label className="labelsize">Gender</label>
              <input
                type="text"
                name="fullname"
                className="form-control"
                required="required"
                data-error="Name is required."
              />  
             
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2 pb-2">
              <label className="labelsize">Phone Number</label>
              <input
                type="text"
                name="phone"
                maxLength={10}
                className="form-control"
                required="required"
                data-error="Name is required."
              />
            </div>
            <div className="col-md-6 mb-2 pb-2">
              <label
                className="labelsize"
                htmlFor="upload your existing FSSAI Licence"
              >
                Uplode File
              </label>
              <input
                type="file"
                className="form-control"
                name="advcopy"
                accept="image/*"
                style={{ textAlign: "center" }}
              />
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-10">&nbsp;&nbsp;</div>
            <div className="col-md-5" style={{ float: "right" }}>
              <input
                className="btn btn-theme btn-sm"
                name="submit"
                type="submit"
                defaultValue="Submit"
                style={{color:"white"}}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div></div>
</section>
<div className="secondary-footer">
  <div className="container">
    <div className="copyright">
      <div className="row">
        <div className="col-lg-12" style={{textAlign:"center"}}>
          {" "}
          <span>
            Copyright 2024 Choutela And Associates tax consultancy Pvt Ltd 
          </span>
        </div>
        
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default GstDetails
