const choutelajson = [
    {id:1,
        img:"https://www.livelaw.in/h-upload/2022/03/29/413397-income-tax.jpg",
       title:"Income Tax",
       slug:"/income-tax-consultant-in-dombivli",
       documentrequire:"Documents Required",
       maincontent:"Filing income tax returns is a legal obligation for all individuals and businesses in India. Failure to file returns can result in penalties and fines. Filing income tax returns is a way to demonstrate compliance with the tax laws of the country. It helps to prevent tax evasion and ensures that citizens are contributing their fair share to the development of the country.",
       accordentitle:"Income tax services refer to the preparation, filing, and assistance with understanding and complying with income tax laws and regulations.",
    
          
       accorden:[
            {
                sectitle:"For Sole Proprietorship / Individual",
               
                ul1:"Aadhaar card, PAN card, and a photograph of the sole proprietor",
                ul2:"Details of Bank account- Bank statement or a canceled cheque",
                ul3:"Office address proof:",
                ol1:"1.Own office – Copy of electricity bill/water bill/landline bill/ property tax receipt/a copy of municipal khata.",
                ol2:"2.Rented office – Rent agreement and NOC (No objection certificate)"

            },
            {
                sectitle:"Partnership deed/LLP Agreement",
                ul1:"Aadhaar card, PAN card, Photograph of all partners.                ",
                ul2:"Details of Bank such as a copy of canceled cheque or bank statement",
                ul4:"Proof of address of Principal place of business and additional place of business :",
                ol1:"1.Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal Khata/property tax receipt",
                ol2:"2.Rented office – Rent agreement and NOC (No objection certificate) from the owner.",
                ul3:"In case of LLP- Registration Certificate of the LLP, Copy of board resolution) from the owner"
            },
            {
                sectitle:"Private limited/Public limited/One person company",
                ul1:"Company’s PAN card",
                ul2:"Certificate of Registration",
                ul3:"MOA (Memorandum of Association) /AOA (Articles of Association)",
                ul4:"Aadhar card, PAN card, a photograph of all Directors",
                ul5:"Details of Bank- bank statement or a canceled cheque",
                ul8:"Proof of Address of Principal place of business and additional place of business:-",
                ul7:"Appointment Proof of authorized signatory- letter of authorization",
                ol1:"1)Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal khata/ property tax receipt",
                ol2:"2)Rented office – Rent agreement and NOC (No objection certificate) from the owner.",

            },
            {
                sectitle:"HUF",
                ul1:"A copy of PAN card of HUF",
                ul2:"Aadhar card of Karta                ",
                ul3:"Photograph",
                ul4:"Details of Bank- bank statement or a copy of a canceled cheque",
                ul5:"Proof of Address of Principal place of business and additional place of business:",
               
                ol1:"1)Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal khata/ property tax receipt",
                ol2:"2)Rented office – Rent agreement and NOC (No objection certificate) from the owner.",
            },
            {
                sectitle:"Society or Trust or Club",
                ul1:"Pan Card of society/Club/Trust",
                ul2:"Certificate of Registration              ",
                ul3:"PAN Card and Photo of Promotor/ Partners",
                ul4:"Details of Bank- a copy of canceled cheque or bank statement",
                ul5:"Appointment Proof of authorized signatory- letter of authorization",
               ul6:"Proof of Address of registered office :",
                ol1:" 1)Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal khata/ property tax receipt",
                ol2:"2)Rented office – Rent agreement and NOC (No objection certificate) from the owner.",
            },
            
            
        ],
        scotitle:"Income Tax Consultant In Dombivli | Choutela & Associates",
        scodesc:"Expert Income Tax Consultants in Dombivli offering personalized tax solutions. Our seasoned professionals provide comprehensive income tax advice, filing services, and strategic planning to optimize your financial well-being. Trust us for accurate and efficient tax consultancy in Dombivli",
        scoslug:"income tax consultant in dombivli",
        title1:"Income Tax Consultant In Dombivli "

    },
    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVnuOyBUL4TwuKlL24DY0vFow2l4-RlMWHiw&usqp=CAU",
        id:2,
        title:"Income Tax Audit",
        slug:"/income-tax-audit-consultant-in-dombivli",
         maincontent:" A tax audit is a procedure to ascertain whether the financial records prepared by a taxpayer adhere to the generally accepted accounting principles and the stipulations of the Income-tax Act. Its purpose is to verify that the books of account and other records are accurately maintained, ensuring the accurate computation of the taxpayer's actual income. This audit also serves to detect and prevent fraudulent activities. It's important to note that a tax audit does not provide the taxpayer with any immunity from scrutiny assessment or disallowance of expenses. Furthermore, only a practicing Chartered Accountant is authorized to conduct a tax audit.",
         accordentitle:"A tax audit is a process to verify whether the books of accounts prepared by a taxpayer comply with the generally accepted accounting principles and the provisions of the Income-tax Act. It is intended to ensure that the books of account and other records are properly maintained and correctly compute the taxpayer’s true income. Such an audit also helps in checking fraudulent practices. A tax audit does not give the assessee any immunity from scrutiny assessment disallowance of expenses1. A tax audit can be conducted only by a Chartered Accountant in practice.",
         accorden:[
             {
                 sectitle:"In which form the tax audit report has to be obtained?",
                 secdecription:"Form No. 3CA/3CB is a format of audit report, whereas Form 3CD is a Statement of particulars required to be furnished under Section 44AB of the Income-tax Act. If the assessee is required to get his books of accounts audited under any other law, it is sufficient for him to get his accounts audited under that law and furnish a report of such audit and a report in form 3CA and 3CD by a Chartered Accountant by the prescribed due date."
             },
             
             
         ],
         scotitle:"Income Tax Audit Consultant In Dombivli | Choutela & Associates",
         scodesc:"Looking for a reliable Income Tax Audit Consultant in Dombivli? Our experienced team offers expert assistance in tax audits, ensuring compliance and maximizing deductions. Contact us for professional tax consultancy services tailored to meet your financial needs.",
         scoslug:"income tax audit consultant in dombivli",
         title1:"Income Tax Audit Consultant In Dombivli "
     }  ,
     
    
     {
        id:3,
        img:"https://img.indiafilings.com/learn/wp-content/uploads/2018/01/12010216/Income-Tax-Return-Forms.jpg",
        title:"Income Tax Return",
        slug:"/income-tax-return-consultant-in-dombivli",
         maincontent:"Any business registered with the Government of India and conducting operations on Indian territory is obligated to file its Income Tax returns. This requirement extends to companies that have been inactive, showing no business transactions and without recorded income or expenses.",
         accordentitle:"Any company that is registered with the Government of India and operating on Indian soil is required to submit its filed IT returns. This is equally applicable for those companies that have been dormant with no business transactions and no registered income or expenses.",
         accorden:[
             {
                sectitle:"Who should file income tax return?",
                ul1:"As per the Income Tax Department the entities required to file IT returns annually are:",
                ul2:"Every company, be it Private limited, LLP or partnership irrespective of the income or loss must file IT returns",
                ul3:"Individuals enjoying income from mutual funds, bonds, stocks, fixed deposits, income from interest, house property,etc",
                ul4:"Individuals receiving income from property under charitable trusts, religious trusts or income from voluntary contributions",
                ul5:"Individuals or companies who want to claim tax refunds",
               ul6:"Salaried persons whose gross income before deductions under section 80C to 80U exceeding the exemption limit",
               ul7:"All individuals with foreign income, foreign assets, NRI’s and tech professionals on onsite deputation.",
               
             },
             {
                sectitle:"People who have opted for one job from another are also eligible.",
               
                ul1:"The IT Department of India has rules for all businesses operating throughout the country to file income taxes each and every year. If need be, TDS return can also be filed and advance taxes can be paid to ensure that the business complies with the IT rules and regulations.",
                ul2:"A proprietorship firm is run by a single person called the proprietor. Proprietorship is not a separate legal entity, that is, both the proprietor (business owner) and the business are the same. Due to this, ITR filing for proprietorship is the same as that of the proprietor. Proprietors are required to file IT returns year after year. The procedure is no different from that of individual income tax filing.",
                ul3:"Proprietors within 60 years of age and whose income exceeds Rs.2.5 lakhs are required to file proprietorship tax returns. Proprietors above 60 years but less than 80 years of age and whose total income exceeds Rs 3 lakhs are eligible. Proprietors above 80 years must file their IT returns if their total income exceeds Rs 5 lakhs.                ",
                ul4:"As per the Income Tax Act, all partnership firms are treated as separate legal entities and are applicable for tax rates that are on par with LLP’s and companies registered in India.",
                ul5:"Irrespective of income or loss, partnership firms are required to do IT filing. If the firm has been commercially inactive with no registered income, a NIL income tax return should be filed within the stipulated date. LLP Tax Return Filing All LLPs or Limited Liability Partnerships are considered separate legal entities and their income tax rate is similar to that of all companies registered in India. The Income Tax Act declares that all LLP’s must file their tax returns irrespective of the loss or gain they have incurred in that year. If the LLP has seen no business activity or registered income, then a NIL income tax must be promptly filed. Company Tax Return Filing All types of business structures like Private Limited Company, Limited Company, Limited Liability Partnership company, One Person Company are registered under the Ministry of Corporate Affairs. All such companies are mandatorily required to file IT returns as prescribed by the Income Tax Act. Requirement for filing company tax returns Any company that is registered with the Government of India and operating on Indian soil is required to submit its filed IT returns. This is equally applicable for those companies that have been dormant with no business transactions and no registered income or expenses.",
              h61:"For Business Tax Return Filing:",
              h62:"Proprietorship Tax Return Filing",
              h63:"Requirements For Filing Proprietorship Tax Returns: ",
              h64:"Partnership Firm Tax Return Filing",
              h65:"Requirement For Filing Partnership Firm Tax Return",
                
            },
             
             
         ],
          scotitle:"Income Tax Return Consultant In Dombivli | Choutela & Associates",
         scodesc:"Looking for expert Income Tax Return consultants in Dombivli? Our experienced professionals provide reliable and personalized tax filing services to ensure compliance and maximize your returns. Contact us for efficient and hassle-free tax solutions tailored to your specific needs.",
         scoslug:"income tax return consultant in dombivli",
         title1:"Income Tax Return Consultant In Dombivli ",
     }  ,
     {
        id:4,
        img:"https://life.futuregenerali.in/media/j1hjxcfv/income-tax-appeal.webp",
        title:"Income Tax Scrutiny/Appeal",
        slug:"/income-tax-scrutiny/appeal-in-dombivli",
         maincontent:" Facing income tax issues? Ensure financial security by understanding your rights and appealing decisions. Navigate the complexities with expert guidance. Protect your income, appeal with confidence. Explore options, secure your financial future. Don't let tax challenges overwhelm you—seek professional assistance for peace of mind and a favorable resolution.",
        accorden:[
             {
                 sectitle:"Advantage",
                 secdecription:"Navigating income tax scrutiny or appeals demands precision and expertise. Our seasoned professionals meticulously analyze your financial records, ensuring compliance and minimizing liabilities. With a proven track record of successful representations, we specialize in simplifying complex tax issues. Trust us to safeguard your interests, providing strategic solutions that stand up to scrutiny. Experience peace of mind with our comprehensive approach to income tax matters, tailored to your unique circumstances."
             },
             
             
         ],
         scotitle:"Income Tax Scrutiny/Appeal In Dombivli | Choutela & Associates",
         scodesc:"Looking for expert Income Tax Return consultants in Dombivli? Our experienced professionals provide reliable and personalized tax filing services to ensure compliance and maximize your returns. Contact us for efficient and hassle-free tax solutions tailored to your specific needs.",
         scoslug:"income tax scrutiny/appeal in dombivli",
         title1:"Income Tax Scrutiny/Appeal In Dombivli",
     }  ,
     {
        id:5,
        img:"https://automatedaccountingsvc.com/wp-content/uploads/2023/10/aas-blog-sept23.png",
        title:"Personal/Business A/C",
        slug:"/personal/business-a/c-in-dombivli",
         maincontent:" Dealing with personal or business income tax challenges? Safeguard your financial stability by gaining a clear understanding of your rights and pursuing appeals when necessary. Navigate the intricate landscape with the support of seasoned experts. Shield your personal or business income, appeal with assurance. Explore available options to secure your financial future. Don't allow tax complications to become burdensome—seek professional assistance for peace of mind and a positive resolution tailored to your personal or business needs.",
        accorden:[
             {
                 sectitle:"Advantage",
                 secdecription:"Experience seamless financial management with our Personal/Business A/C solution! Enjoy the convenience of a single account catering to both personal and business needs. Streamline your finances, track expenses, and maximize efficiency with ease. Benefit from tailored features that empower you to navigate your financial landscape effortlessly. Simplify your life and elevate your business with a versatile account designed to meet all your financial goals. Embrace the advantage of unified financial control today!"
             },
             
             
         ],
         scotitle:"Personal/Business A/C In Dombivli | Choutela & Associates",
         scodesc:"Discover personalized and business banking solutions in Dombivli with our range of accounts. Whether you're managing personal finances or growing your business, find tailored banking services that suit your needs. Explore our accounts today for a seamless and efficient banking experience in Dombivli.",
         scoslug:"personal/business a/c in dombivli",
         title1:"Personal/Business A/C In Dombivli ",
     }  ,
     
     {
        id:5,
        img:"https://www.aplservices.in/images/new/s5.jpg",
        title:"Tax Saving Advisory",
        slug:"/tax-saving-advisory-in-dombivli",
        accordentitle:" Tax Saving Advisory offers expert guidance for individuals and businesses seeking to optimize their tax liabilities. Our certified professionals, including CPAs and enrolled agents, provide comprehensive advice on maximizing deductions, leveraging tax credits, and implementing strategic tax-saving approaches. From filing tax returns to reducing taxable income and exploring investment opportunities, we tailor our services to your unique needs. Trust us for insightful counsel on federal and state taxes, as well as assistance in setting up tax-advantaged retirement plans like IRAs and 401(k)s. Save more with Tax Saving Advisory.",
         maincontent:"Tax saving advisory is a service that helps individuals and businesses to save money on their taxes by taking advantage of available deductions, credits, and other tax-saving strategies. It includes advice on filing tax returns, finding ways to reduce taxable income, as well as taking advantage of investment opportunities and estate planning. The service may also include advice on how to set up a tax-advantaged retirement plan, such as an IRA or 401(k). Tax saving advisors may be certified public accountants, enrolled agents, or other financial professionals. They provide advice on both federal and state taxes.",
        accorden:[
             {
                 sectitle:"Tax Planning:",
                 secdecription:"Tax planning is a process of organizing your finances and investments in order to reduce your tax liability. Tax planning may involve taking advantage of deductions and credits, using tax-advantaged investments, or employing other strategies to reduce taxes. Tax planning should be done with the help of a qualified tax advisor, who can provide advice based on your individual situation. Tax planning can help you maximize your after-tax income and wealth. It can also help you save money on taxes and reduce your risk of being audited. Tax planning should be done on an ongoing basis, as tax laws and regulations are constantly changing."
             },
             {
                sectitle:"Tax Preparation:",
                secdecription:"Tax preparation is the process of gathering documents and information to prepare a tax return, which is needed to file with the IRS. Tax preparation includes gathering financial documents, such as W-2s, 1099s, and other income statements, as well as any applicable deductions or credits. A tax preparer can help you organize your documents, complete the paperwork, and file your return. Tax preparation can be done by an individual or through a professional tax preparer. Professional tax preparers are typically certified public accountants (CPAs) or other tax professionals with experience in tax law and filing. They can help make sure your return is accurate and complete, so that you can get the most favorable outcome."
            },
            {
                sectitle:" Investment Advice:",
                secdecription:"Investment advice is professional advice given by an investment professional, such as a stockbroker, financial planner, or investment analyst, about what investments to make and when to make them in order to meet specific financial goals. Investment advice typically focuses on making investments that are expected to earn a good return while also minimizing risk. Investment advice should also be tailored to the individual's goals, needs, and risk tolerance."
            },
            {
                sectitle:" Estate Planning:",
                secdecription:"Estate planning is the process of anticipating and arranging for the disposal of an individual's estate during their life. Estate planning typically involves the creation of a will or trust, as well as the selection of beneficiaries to receive the estate upon the individual's death. Estate planning may also include the selection of executors and guardians to manage the estate and provide for the individual's dependents. Estate planning typically involves tax planning and the coordination of other legal documents and financial instruments."
            },
             
         ],
         scotitle:"Tax Saving Advisory In Dombivli | Choutela & Associates",
         scodesc:"Discover expert tax-saving advisory services in Dombivli to optimize your financial planning. Our professionals provide personalized guidance for maximizing deductions and minimizing liabilities. Secure your financial future with strategic tax-saving solutions in Dombivli.",
         scoslug:"tax saving advisory in dombivli",
         title1:"Tax Saving Advisory In Dombivli ",
     }  ,
     
]
export default choutelajson;