const gstjson =[
    {
        img:"https://www.indiafilings.com/learn/wp-content/uploads/2023/01/gst-registration.jpg",
        title:"GST Registration",
       slug:"/gst-registration-in-dombivli",
       documentrequire:"Documents Required",
       accordentitle:"Goods and Services Tax (GST) registration is a process by which businesses register with the Indian government to become a GST-compliant taxpayer.",
       name1: "Income Tax:",
          detail:
            " a tax on individuals' and entities' income earned during a financial year.",
        
          name2: "Corporate Tax:",
          detail2: " a tax on the profits earned by companies.",
    
          name3: "Goods and Services Tax (GST):",
          detail3: "a consumption-based tax on goods and services.",
     
          name4: "Value Added Tax (VAT): ",
          detail4: "a consumption-based tax on goods and services.",
     
          name5: "Excise duty: ",
          detail5: "a tax on certain goods produced within India.",
      
          name6: "Property Tax: ",
          detail6: "a tax on the ownership or possession of property.",
      
          name7: "Securities Transaction Tax (STT):",
          detail7: "a tax on the value of securities traded on stock exchanges.",
       
          name7: "Wealth Tax: ",
          detail7: "a tax on the wealth of individuals and entities above a certain threshold. These taxes are collected by the Central Board of Direct Taxes (CBDT) and state tax departments, and used to fund government services and programs.",
     
          name8: "Goods and Services Tax (GST):",
          detail8: "a consumption-based tax on goods and services.",
          name9: "Goods and Services Tax (GST):",
          detail9: "a consumption-based tax on goods and services.",
       documentrequire:"Documents Required",
       maincontent:
         "In India, taxes are levied by the central and state governments on income, goods and services, property, and wealth. The main taxes include:",
      
       
        accorden:[
            {
                sectitle:"For Sole Proprietorship / Individual",
               
                ul1:"Aadhaar card, PAN card, and a photograph of the sole proprietor",
                ul2:"Details of Bank account- Bank statement or a canceled cheque",
                ul3:"Office address proof:",
                ol1:"1.Own office – Copy of electricity bill/water bill/landline bill/ property tax receipt/a copy of municipal khata.",
                ol2:"2.Rented office – Rent agreement and NOC (No objection certificate)"

            },
            {
                sectitle:"Partnership deed/LLP Agreement",
                ul1:"Aadhaar card, PAN card, Photograph of all partners.                ",
                ul2:"Details of Bank such as a copy of canceled cheque or bank statement",
                ul4:"Proof of address of Principal place of business and additional place of business :",
                ol1:"1.Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal Khata/property tax receipt",
                ol2:"2.Rented office – Rent agreement and NOC (No objection certificate) from the owner.",
                ul3:"In case of LLP- Registration Certificate of the LLP, Copy of board resolution) from the owner"
            },
            {
                sectitle:"Private limited/Public limited/One person company",
                ul1:"Company’s PAN card",
                ul2:"Certificate of Registration",
                ul3:"MOA (Memorandum of Association) /AOA (Articles of Association)",
                ul4:"Aadhar card, PAN card, a photograph of all Directors",
                ul5:"Details of Bank- bank statement or a canceled cheque",
                ul8:"Proof of Address of Principal place of business and additional place of business:-",
                ul7:"Appointment Proof of authorized signatory- letter of authorization",
                ol1:"1)Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal khata/ property tax receipt",
                ol2:"2)Rented office – Rent agreement and NOC (No objection certificate) from the owner.",

            },
            {
                sectitle:"HUF",
                ul1:"A copy of PAN card of HUF",
                ul2:"Aadhar card of Karta                ",
                ul3:"Photograph",
                ul4:"Details of Bank- bank statement or a copy of a canceled cheque",
                ul5:"Proof of Address of Principal place of business and additional place of business:",
               
                ol1:"1)Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal khata/ property tax receipt",
                ol2:"2)Rented office – Rent agreement and NOC (No objection certificate) from the owner.",
            },
            {
                sectitle:"Society or Trust or Club",
                ul1:"Pan Card of society/Club/Trust",
                ul2:"Certificate of Registration              ",
                ul3:"PAN Card and Photo of Promotor/ Partners",
                ul4:"Details of Bank- a copy of canceled cheque or bank statement",
                ul5:"Appointment Proof of authorized signatory- letter of authorization",
               ul6:"Proof of Address of registered office :",
                ol1:" 1)Own office – Copy of electricity bill/water bill/landline bill/ a copy of municipal khata/ property tax receipt",
                ol2:"2)Rented office – Rent agreement and NOC (No objection certificate) from the owner.",
            },

        ]
        ,scotitle:"GST Registration In Dombivli | Choutela & Associates",
        scodesc:"Streamline your business in Dombivli with hassle-free GST registration services. Unlock growth opportunities and comply with tax regulations seamlessly. Expert assistance for smooth GST registration process in Dombivli. Get started today!",
        scoslug:"gst registration in dombivli",
        title1:"GST Registration In Dombivli "
    },
  
    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoWiGJf8ONGw7xjte2VQmFqfjEokWWN4q1WQ&usqp=CAU",
        title:"GST  Return Filing",
       slug:"/gst-return-filing-in-dombivli",
       whathesay:"Who Should File GST Return?",
       whathesaydesc:"All business owners and dealers who have registered under the GST system must file GST returns according to the nature of their business or transactions.",
        maincontent:" All entrepreneurs and merchants who have enrolled under the GST framework are obligated to submit GST returns based on the characteristics of their trade or transactions.Standard Enterprises. Enterprises enrolled under the Composition Scheme. Various categories of business proprietors and traders.Revisions. Automatically generated Returns. Tax Notifications",
        whathesaytitle:"Regular Businesses.",
        whathesaytitle:"Amendments.",
        whathesaytitle:"Businesses registered under the Composition Scheme.",
        whathesaytitle:"Auto-drafted Returns.",
        documentrequire:"Types Of GST Returns",
        whathesaytitle:"Other types of business owners and dealers.",
        whathesaytitle:"Tax Notice.",
        secondtitle:"Who should file GST Return?",
        seconddetail:"All business owners and dealers who have registered under the GST system must file GST returns according to the nature of their business or transactions.",
        list1:"Regular Businesses.",
        list2:"Amendments.",
        list3:"Businesses registered under the Composition Scheme.  ",
        list4:"Auto-drafted Returns.",
        list5:"Other types of business owners and dealers.",
        list6:"Tax Notice.",
        accorden:[
            {
                sectitle:"GSTR-1 Return – Due on 10th of Every Month",
                secdecription:"GSTR-1 is the return to be furnished for reporting details of all outward supplies of goods and services made. In other words, it contains the invoices and debit-credit notes raised on the sales transactions for a tax period. GSTR-1 is to be filed by all normal taxpayers who are registered under GST, including casual taxable persons. The filing frequency of GSTR-1 is currently as follows: (a) Monthly, by 11th* of every month- If the business either has an annual aggregate turnover of more than Rs.5 crore or has not opted into the QRMP scheme. (b) Quarterly, by 13th** of the month following every quarter- If the business has opted into the QRMP scheme.",
            },
            {
                sectitle:"GSTR-2 Return – Due on 15th of Every Month",
                secdecription:"GSTR 2 or return of inward supplies must be filed before the 15th of each month. When the supplier files the outward supplies in the GSTR 2 returns, the details in the receipt should match with the details of the supplies as filed by the supplier. When the supplier provides the receipt the receiver should verify, validate, modify or even delete, if necessary – the details furnished by the suppliers.",
            },
            {
                sectitle:"GSTR-3 Return – Due on 20th of Every Month",
                secdecription:"GSTR 3 or monthly GST return is due on the 20th of every month. The GST Portal generates the Part A of GSTR 3 automatically based on information furnished through Form GSTR-1, Form GSTR-2 and based on other liabilities of preceding tax periods. The taxpayer can discharge his liability towards tax, interest, penalty, fees or any other amount payable under the Act or the provisions of this Chapter by debiting the electronic cash ledger or electronic credit ledger and include the details in Part B of the return in FORM GSTR-3.",
            }
            ,
            {
                sectitle:"GSTR-4 Return – Quarterly Return for Composition Suppliers Due on 18th",
                secdecription:"GSTR 4 or GST quarterly return for composition supplier is due 18 days from the end of the quarter. Hence, GSTR 4 return will be due on 18th April, 18th July, 18th October and 18th January. Based on details contained in FORM GSTR-4A, and where required, after adding, correcting or deleting the details, the taxpayer can file the quarterly return in FORM GSTR-4. ",
            }
            ,
            {
                sectitle:"GSTR-5 Return – Monthly Return for Non-Resident Taxable Persons",
                secdecription:"GSTR 5 return must be filed by persons registered under GST as a non-resident taxable person before the 20th and within 7 days from last day of registration. In GSTR-5, the taxpayer must file information and details of outward supplies and inward supplies. ",
            }
            ,
            {
                sectitle:"GSTR-6 Return – Monthly Return for Input Service Distributorsh",
                secdecription:"Taxpayers registered as an Input Service Distributors must file GSTR-6 returns on or before the 13th of every month. Based on FORM GSTR-6A, the taxpayer can file the return after adding, correcting or deleting the details, furnish a return, containing the details of tax invoices on which credit has been received and those issued.",
            }
            ,
            {
                sectitle:"GSTR-7 Return – Monthly Return for Tax Deductors",
                secdecription:"All the taxpayers registered under GST for TDS must file GSTR-7 returns. GSTR-7 return is due on or before the 10th of every month. The GST Council shall publish all the details furnished in Form GSTR-8, as available in Part C of Form 2A and Form 4A to other taxpayers. ",
            }
            ,
            {
                sectitle:"GSTR-8 Return – Monthly Return for E-Commerce Operator",
                secdecription:"GSTR-8 return must be filed by E-Commerce Operator on or before the 10th of every month. E-Commerce operators must provide details of outward supplies of goods or services or both made through it, including the supplies returned through it and the amount collected by it. The GST Council shall provide all the details furnished by ecommerce operators to each of the suppliers in Part C of FORM GSTR-2A.",
            }
            ,
            {
                sectitle:"GSTR-9 Return – Annual GST ReturnGSTR-4 Return – Quarterly Return for Composition Suppliers Due on 18th",
                secdecription:"GSTR-9 return or annual GST return must be filed by taxpayers on or before the 31st of December. Non-resident taxable persons, Casual taxable persons, TDS deductors and TCS collectors registered under GST Composition scheme can avoid filing GSTR-9 return if the annual turnover exceeds Rs.2 crore. However, under these circumstances, the taxpayer shall audit the returns by a Chartered Accountant or Cost Accountant.",
            }
            ,
            {
                sectitle:"GSTR-10 Return – Final GST Return",
                secdecription:"GSTR-10 Return – Final GST Return The taxpayer shall file the GSTR 10 or final GST return within 3 months from the date of cancellation of GST registration.",
            }
            ,
            {
                sectitle:"GSTR-11 Return – GST Return for UIN Holders",
                secdecription:"Form GSTR-11 or GST return for UIN holders must be filed by persons having UIN under GST to claim a refund of taxes paid on his inward supplies. ",
            }
        ]
        ,scotitle:"GST Return Filing In Dombivli | Choutela & Associates",
        scodesc:"Efficient GST return filing services in Dombivli. Our expert team ensures accurate and timely filing, helping businesses comply with GST regulations seamlessly. Contact us for hassle-free GST return filing in Dombivli.",
        scoslug:"gst return filing in dombivli",
        title1:"GST Return Filing In Dombivli"
    },
    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoWiGJf8ONGw7xjte2VQmFqfjEokWWN4q1WQ&usqp=CAU",
        title:" Gst Annual Return Filing",
       slug:"/gst-annual-return-filing-in-dombivli",
       accordentitle:" GSTR-9 is an annual return mandatory for GST-registered taxpayers, summarizing both outward and inward supplies for the financial year. It covers details under CGST, SGST, and IGST.Additionally, taxpayers might need to submit GSTR-9C, a reconciliation statement. GSTR-9C ensures alignment between annual financial statements and GSTR-9 figures, enhancing accuracy in GST transaction reporting.GSTR-9 consolidates monthly or quarterly returns, offering a comprehensive overview of GST activities. GSTR-9C, on the other hand, provides a detailed reconciliation for a more thorough examination of financial data. These filings collectively contribute to a transparent and compliant GST reporting process for businesses.",
        maincontent:" GSTR 9 is the annual return that is to be to be filed yearly by the taxpayers registered under GST.GSTR 9 is to be filed yearly by the taxpayers registered under GST. It consists of details regarding the outward and the inward supplies made during the relevant financial year.GSTR 9 consists of details regarding the outward and the inward supplies made or received during the relevant financial year under CGST, SGST, and IGST. It is a consolidation of all the monthly/quarterly returns filed in that year.",
        accorden:[
            {
                sectitle:"GSTR-9 Return – Annual GST Return",
                secdecription:"GSTR-9 return or annual GST return must be filed by taxpayers on or before the 31st of December. Non-resident taxable persons, Casual taxable persons, TDS deductors and TCS collectors registered under GST Composition scheme can avoid filing GSTR-9 return if the annual turnover exceeds Rs.2 crore. However, under these circumstances, the taxpayer shall audit the returns by a Chartered Accountant or Cost Accountant.",
            }
        ]
        ,scotitle:"Gst Annual Return Filing In Dombivli | Choutela & Associates",
        scodesc:"Effortlessly navigate GST annual return filing in Dombivli with our expert services. Ensure compliance, accuracy, and timely submission of your GST returns. Trust us for a seamless experience in Dombivli's tax landscape.",
        scoslug:"gst annual return filing in dombivli",
        title1:"Gst Annual Return Filing In Dombivli",
    },
    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLfWfwR7TvSXLeyNCVrQ9SeS5_LYHCJ-Fj7Q&usqp=CAU",
        title:" Gst Scrutiny/Appeal",
       slug:"/gst-scrutiny/appeal-in-dombivli",
        maincontent:"Explore our GST appeal services for expert assistance in resolving disputes. Our team ensures a thorough review of your case, providing comprehensive support. Prioritize financial security with our dedicated approach. Trust us to navigate the complexities, offering peace of mind and strategic solutions for your GST-related concerns",
        accorden:[
            {
                sectitle:"GGst Scrutiny/Appeal",
                secdecription:"Leveraging GST appeal procedures empowers businesses to rectify potential discrepancies and ensure fair taxation outcomes. By understanding and navigating the appeal process, organizations can mitigate financial risks and maintain compliance. Additionally, prioritizing stringent security measures in GST transactions safeguards sensitive data, instilling confidence in clients and stakeholders. Proactively addressing appeal opportunities and bolstering security protocols not only protects financial integrity but also contributes to a robust and trustworthy business image in the competitive market landscape.",
            }
        ]
        ,scotitle:" Gst Scrutiny/Appeal In Dombivli | Choutela & Associates",
        scodesc:"Effortlessly navigate GST annual return filing in Dombivli with our expert services. Ensure compliance, accuracy, and timely submission of your GST returns. Trust us for a seamless experience in Dombivli's tax landscape.",
        scoslug:" gst scrutiny/appeal in dombivli",
        title1:" Gst Scrutiny/Appeal In Dombivli",
        
    },
]


export default gstjson