import React, { useState } from "react";
import "../css/base.css";
import { Link } from "react-router-dom";
import { FaRegClock } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import logo from "../assets/logo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import choutelajson from "./choutelajson";
import gstjson from "../components/gstjson";
import rocjson from "../components/Rocjson";
import Tdsjson from "./Tdsjson";
import OtherServices from "./OtherServices";
import Otherlegalservice from "./Otherlegalservices";
import Registration from "./Registration";
import { Nav, Navbar, Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
const Hadder = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div>
      <header id="site-header" className="header header-3">
        <div className="header-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex align-items-center justify-content-between">
                <div className="topbar-link d-flex align-items-center">
                  <div className="d-flex align-items-center me-3">
                    {" "}
                    <IoMail className="choutalaicon" />
                    <div>
                      <a href="#">:choutelaandassociates@gmail.com</a>
                    </div>
                  </div>
                  <div className="d-none d-md-flex align-items-center me-3">
                    {" "}
                    <FaPhone className="choutalaicon" />
                    <div>
                      <a href="#"> : +91 9892844895 / +91 8077794482 / +91 9892744895 </a>
                    </div>
                  </div>
                  <div className="d-none d-lg-flex align-items-center">
                    {" "}
                    <FaRegClock className="choutalaicon" />
                    <div>
                      <p className="mb-0">: Mon-Sat 10:00am to 8:00pm</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="language-selection me-2"></div>
                  <div className="social-icons d-none d-md-block">
                    <ul className="list-inline">
                      <li>
                        <a href="https://www.facebook.com/choutelaadvocateandtaxconsultants/">
                          <FaFacebookF className="choutalaicon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/brij_choutela/">
                          <FaInstagram className="choutalaicon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-middle">
          <div className="container">
            <div className="row">
              <div className="col-md-12 d-flex align-items-center justify-content-between">
                <a className="navbar-brand logo">
                  <img
                    id="logo-img"
                    className="img-fluid"
                    src={logo}
                    style={{ width:"285px", height:"50px"}}
                    alt=""
                  />
                </a>
                
                {/*<a class="btn btn-theme d-none d-sm-block" style="color:#fff" href="#"><span>&nbsp;</span></a>*/}
              </div>
            </div>
          </div>
        </div>
        <div id="header-wrap" className="displaynoneclass">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                {/* Navbar */}
                <nav className="navbar navbar-expand-lg">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={handleToggle}
                  >
                    {" "}
                    <span />
                    <span />
                    <span />
                  </button>
                  <div
                    // className="collapse navbar-collapse"
                    id="navbarNavDropdown"
                    className={`collapse navbar-collapse ${
                      isNavOpen ? "show" : ""
                    }`}
                  >
                    <ul className="navbar-nav">
                      {/* Home */}
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link active" href="/">
                          <Link to="/">Home</Link>
                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link " href="/aboutus">
                          <Link to="/aboutus"> AboutUs</Link>
                        </a>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          Income Tax Services
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {choutelajson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="" href={slug}>
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                        
                          data-bs-toggle="dropdown"
                        >
                          Goods And Service Tax
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {gstjson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="" href="/GstDetails">
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                         
                          data-bs-toggle="dropdown"
                        >
                          Intelectual Property
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {rocjson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="" href="/GstDetails">
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                         
                          data-bs-toggle="dropdown"
                        >
                          Company/LLP
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {Tdsjson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="" href="/GstDetails">
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                       
                          data-bs-toggle="dropdown"
                        >
                          Other Legal Services
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {Otherlegalservice.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="">
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                          
                          data-bs-toggle="dropdown"
                        >
                        Registration
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {Registration.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="">
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        {" "}
                        <a
                          className="nav-link  dropdown-toggle"
                        
                          data-bs-toggle="dropdown"
                        >
                        Loan Services
                        </a>
                        <div className="dropdown-menu">
                          <ul className="list-unstyled">
                            {OtherServices.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <li>
                                  <a className="">
                                    <Link to={slug}>{title}</Link>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link " href="/gallery">
                          <Link to="/gallery">Gallery</Link>
                        </a>
                      </li>

                      <li className="nav-item">
                        {" "}
                        <a className="nav-link " href="/contectus">
                          <Link to="/contectus">Contact Us</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-bar-component d-lg-none d-block">
        <section style={{padding:"10px 0px"}} className="NavSection">
          <div className="container">
            <Navbar collapseOnSelect expand="lg" variant="dark" >
              <Container>
                {/* <Navbar.Brand href="#home">Logo</Navbar.Brand> */}
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Brand href="#home">
                  {/* <div className='NavLogo'>
                    <img src={logo} alt="" />
                  </div> */}
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                  <Nav>
                    <Nav.Link href="#home"> <Link to="/">Home</Link></Nav.Link>
                    <Nav.Link href="#About"> <Link to="/aboutus"> AboutUs</Link> </Nav.Link>
                    <NavDropdown title="Income Tax Services" id="basic-nav-dropdown">
                   
                      {choutelajson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <>
                            <NavDropdown.Item href="#action/3.1">
                              <Link to={`${slug}`}>
                              {title}
                              </Link>
                            </NavDropdown.Item>
                          </>
                              );
                            })}
                    </NavDropdown>
                    <NavDropdown title="Goods And Service Tax" id="basic-nav-dropdown">
                   
                    {gstjson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <>
                            <NavDropdown.Item href="#action/3.1">
                            <Link to={slug}>{title}</Link>
                            </NavDropdown.Item>
                          </>
                              );
                            })}
                    </NavDropdown>
                    <NavDropdown title="Intelectual Property" id="basic-nav-dropdown">
                   
                      {rocjson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <>
                            <NavDropdown.Item href="#action/3.1">
                            <Link to={slug}>{title}</Link>
                            </NavDropdown.Item>
                          </>
                              );
                            })}
                    </NavDropdown>
                    <NavDropdown title="Company/LLP" id="basic-nav-dropdown">
                   
                      {Tdsjson.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <>
                            <NavDropdown.Item href="#action/3.1">
                            <Link to={slug}>{title}</Link>
                            </NavDropdown.Item>
                          </>
                              );
                            })}
                    </NavDropdown>
                    <NavDropdown title="Other Legal Services" id="basic-nav-dropdown">
                   
                      {Otherlegalservice.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <>
                            <NavDropdown.Item href="#action/3.1">
                            <Link to={slug}>{title}</Link>
                            </NavDropdown.Item>
                          </>
                              );
                            })}
                    </NavDropdown>
                    <NavDropdown title=" Registration" id="basic-nav-dropdown">
                   
                      {Registration.map((ele) => {
                              const { title, slug } = ele;
                              return (
                                <>
                            <NavDropdown.Item href="#action/3.1">
                            <Link to={slug}>{title}</Link>
                            </NavDropdown.Item>
                          </>
                              );
                            })}
                    </NavDropdown>
                    <NavDropdown title=" Loan Services" id="basic-nav-dropdown">
                   
                   {OtherServices.map((ele) => {
                           const { title, slug } = ele;
                           return (
                             <>
                         <NavDropdown.Item href="#action/3.1">
                         <Link to={slug}>{title}</Link>
                         </NavDropdown.Item>
                       </>
                           );
                         })}
                 </NavDropdown>
                    
                    <Nav.Link href="#About us"> <Link to="/gallery">Gallery</Link> </Nav.Link>
                    <Nav.Link href="#Contact us">  <Link to="/contectus">Contact Us</Link></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </section>
      </div>
      </header>
      
    </div>
  );
};

export default Hadder;
