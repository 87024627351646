import React from 'react'
import { Tabs } from 'antd';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { TbTargetArrow } from "react-icons/tb";
import { GiTeamIdea } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { MetaTags } from "react-meta-tags";
import ab1 from "../assets/aboutus/ab1.jpg"
import ab2 from "../assets/aboutus/ab2.png"
const { TabPane } = Tabs;
const Aboutus = () => {
  return (
    <div>
       <MetaTags>
      <title> Taxation Lawyers In Dombivli | Choutela & Associates</title>
        <meta title="Taxation Lawyers In Dombivli | Choutela & Associates" />
        <meta
          name="description"
          content="Looking for experienced taxation lawyers in Dombivli? Our skilled legal professionals specialize in taxation law, providing expert advice and representation for individuals and businesses. Ensure compliance and navigate complex tax issues with confidence. Contact us for tailored solutions and dedicated advocacy in Dombivli."
        />
        <meta
          name="keywords"
          content="taxation lawyers in dombivli"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Taxation Lawyers In Dombivli | Choutela & Associates" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Looking for experienced taxation lawyers in Dombivli? Our skilled legal professionals specialize in taxation law, providing expert advice and representation for individuals and businesses. Ensure compliance and navigate complex tax issues with confidence. Contact us for tailored solutions and dedicated advocacy in Dombivli." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags> 
      <section
  className="page-title parallaxie"
  data-bg-img="https://krishnataxconsultancy.com/images/bg/08.jpg"
  style={{
    backgroundImage: 'url("https://krishnataxconsultancy.com/images/bg/08.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center 108.66px"
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-12">
        <h1>About Us</h1>
        <nav aria-label="breadcrumb" className="page-breadcrumb">
       
        </nav>
      </div>
    </div>
  </div>
</section>
<section>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6 col-12">
        <div className="h-25 w-25 theme-bg rounded position-absolute" />
        <div className="ps-5 mt-5 z-index-1">
          <img
            className="img-fluid rounded box-shadow"
            src={ab1}
            alt=""
          />
        </div>
      </div>
      <div className="col-lg-6 col-12 mt-5 mt-lg-0">
        <div className="section-title">
          <h6>About Us</h6>
          <h2 className="title">Consulting, Business and innovation</h2>
          <p>
            We work in all fields of business and finance, including auditing,
            taxation, financial and general management. Engaged in public
            practice work, others work in the private sector and employed by
            government bodies. chartered accountant credential qualifies an
            individual to offer financial consultation and keep financial
            records.
          </p>
        </div>
        <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span style={{fontSize:"15px"}}>
           <TbTargetArrow  style={{fontSize:"25px"}}/>
            Our Mission
          </span>
        }
        key="1"
      >
       Our mission is to excel in delivering comprehensive taxation, auditing, financial, and general management services. We strive to empower our clients by ensuring their seamless daily business operations. Our commitment lies in providing up-to-date tax information, strategic advice, and timely compliance with tax authorities to mitigate financial risks. With a focus on excellence and client satisfaction, we aim to be a trusted partner in navigating the complex landscape of taxation, contributing to our clients' success and growth.
      </TabPane>
      <TabPane
        tab={
          <span style={{fontSize:"15px"}}>
        <GiTeamIdea style={{fontSize:"25px"}} />
            Our Vision
          </span>
        }
        key="2"
      >
       To be the foremost authority in taxation, auditing, financial, and general management services, delivering unparalleled quality and staying abreast of evolving industry dynamics. We aspire to empower our clients, ensuring seamless daily business operations through the provision of cutting-edge tax information and strategic advice. Our vision is to be the trusted partner, guiding clients with precision and timeliness in compliance with tax authorities, safeguarding against financial losses, and fostering sustained growth in their endeavors.
      </TabPane>
      <TabPane
        tab={
          <span style={{fontSize:"15px"}}>
           <GiTakeMyMoney style={{fontSize:"25px"}} />
            Our Value
          </span>
        }
        key="3"
      >
        As dedicated tax consultants, our mission is to deliver top-notch services in taxation, auditing, financial, and general management. We stay abreast of the latest industry knowledge to empower our clients in conducting seamless daily business activities. By providing up-to-date tax information, valuable advice, and ensuring timely compliance with tax authorities, we safeguard our clients from potential financial losses. Trust us to be your reliable partner in navigating the complexities of taxation and optimizing your financial strategy.
      </TabPane >
    </Tabs>
       
      </div>
    </div>
  </div>
</section>
<section>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-12 col-12 mt-5 mt-lg-0">
        <div className="lined">
          <h3>Core Team</h3>
          <span className="bolded-line" />
        </div>
      </div>
      <div className="col-lg-3 col-12">
        <img className="img-fluid imgabout" src={ab2} alt="" />
      </div>
      <div className="col-lg-9 col-12 mt-5 mt-lg-0">
        <div className="section-title">
          <h5>Choutela & Associates </h5>
          <p>(LL.B,M.Com ,M.B.A,G.D.C.& A.)</p>
          <h6 className="title">Director</h6>
        </div>
        <p>
          We work in all fields of business and finance, including auditing,
          taxation, financial and general management. Engaged in public practice
          work, others work in the private sector and employed by government
          bodies. chartered accountant credential qualifies an individual to
          offer financial consultation and keep financial records.
        </p>
      </div>
    </div>
  </div>
</section>

<div className="secondary-footer">
  <div className="container">
    <div className="copyright">
      <div className="row">
        <div className="col-lg-12" style={{textAlign:"center"}}>
          {" "}
          <span>
            Copyright 2024 Choutela And Associates tax consultancy Pvt Ltd 
          </span>
        </div>
        
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Aboutus
