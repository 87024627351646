import React from 'react'

const Gallery = () => {
  return (
    <div>
      <section
  className="page-title parallaxie"
  data-bg-img="https://krishnataxconsultancy.com/images/gallery/backimage.png"
  style={{
    backgroundImage: 'url("https://krishnataxconsultancy.com/images/gallery/backimage.png")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center 108.66px"
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-12">
        <h1>Gallery</h1>
        <nav aria-label="breadcrumb" className="page-breadcrumb">
        
        </nav>
      </div>
    </div>
  </div>
</section>
<section id="portfolio" className="wow fadeInUp">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-12 col-12 mt-5 mt-lg-0">
        <div className="lined">
          <h3>Gallery</h3>
          <span className="bolded-line" />
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col-lg-3 col-md-4 " style={{padding:"0px 10px"}}>
          <div className="portfolio-item wow fadeInUp">
            <a
              href="images/gallery/office_main_door.jpg"
              className="portfolio-popup"
            >
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2023/1/ZG/UK/GD/51628118/sales-tax-consultancy-500x500.jpg"
                width={300}
                height={180}
                alt=""
              />
              <div className="portfolio-overlay">
                <div className="portfolio-info">
                  <h2 className="wow fadeInUp">Entrance</h2>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 " style={{padding:"0px 10px"}}>
          <div className="portfolio-item wow fadeInUp">
            <a href="images/gallery/gstreturn.jpg" className="portfolio-popup">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdnq7g9XOte19WImmW_r2E6hy5bMnysOhv1HMlSsSlX1h6OeuVnECuhZPsCT1TlJCOZa4&usqp=CAU"
                width={300}
                height={180}
                alt=""
              />
              <div className="portfolio-overlay">
                <div className="portfolio-info">
                  <h2 className="wow fadeInUp">GST Return</h2>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 " style={{padding:"0px 10px"}}>
          <div className="portfolio-item wow fadeInUp">
            <a href="https://www.godigit.com/content/dam/godigit/directportal/en/contenthm/itr-for-self-employed.jpg" className="portfolio-popup">
              <img
                src="https://www.godigit.com/content/dam/godigit/directportal/en/contenthm/itr-for-self-employed.jpg"
                width={300}
                height={180}
                alt=""
              />
              <div className="portfolio-overlay">
                <div className="portfolio-info">
                  <h2 className="wow fadeInUp">Director Cabin</h2>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 " style={{padding:"0px 10px"}}>
          <div className="portfolio-item wow fadeInUp">
            <a
              href="images/gallery/office_staff1.jpg"
              className="portfolio-popup"
            >
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2021/10/QT/OI/TI/99019597/new-compnay-incorporation-500x500.jpg"
                width={300}
                height={180}
                alt=""
              />
              <div className="portfolio-overlay">
                <div className="portfolio-info">
                  <h2 className="wow fadeInUp"> Valuable Staff</h2>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 " style={{padding:"0px 10px"}}>
          <div className="portfolio-item wow fadeInUp">
            <a
              href="images/gallery/office_space.jpg"
              className="portfolio-popup"
            >
              <img
                src="https://taxcons.in/upload/services/partnership-firm-registration.jpg"
                width={300}
                height={180}
                alt=""
              />
              <div className="portfolio-overlay">
                <div className="portfolio-info">
                  <h2 className="wow fadeInUp">Workspace</h2>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 " style={{padding:"0px 10px"}}>
          <div className="portfolio-item wow fadeInUp">
            <a
              href="images/gallery/companyregistration.jpg"
              className="portfolio-popup"
            >
              <img
                src="https://rrdevs.net/demos/html/lawgist/assets/img/about/service-details-thumb1-1024x587.png"
                width={300}
                height={180}
                alt=""
              />
              <div className="portfolio-overlay">
                <div className="portfolio-info">
                  <h2 className="wow fadeInUp">Company Registration</h2>
                </div>
              </div>
            </a>
          </div>
        </div>
     
       
      
      </div>
    </div>
  </div>
</section>
<div className="secondary-footer">
  <div className="container">
    <div className="copyright">
      <div className="row">
        <div className="col-lg-12" style={{textAlign:"center"}}>
          {" "}
          <span>
            Copyright 2024 Choutela And Associates tax consultancy Pvt Ltd 
          </span>
        </div>
        
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Gallery
