
import './App.css';
import Hadder from './components/Hadder';
import Home from './pages/Home';
import "../src/css/base.css"
import "../src/css/shortcode.css"
import "../src/css/style.css"
import "../src/css/Color.css"
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from './pages/Gallery';
import Details from './pages/Details';
import "../src/css/responsive.css"
import choutelajson from './components/choutelajson';
import gstjson from "./components/gstjson"
import GstDetails from './pages/GstDetail';
import Rocjson from './components/Rocjson';
import OtherServices from './components/OtherServices';

import ROCdetail from './pages/ROCdetail';
import Tdsdetails from './pages/Tdsdetails';
import Tdsjson from './components/Tdsjson';
import Otherservicedetail from './pages/Otherservicedetail';
import Otherlegaldet from './pages/Otherlegaldet';
import Registration from './components/Registration';
import Registrationdetail from "./pages/Registiondetail"
import OtherlegalServices from './components/Otherlegalservices';
import OtherlegalServicesdetails from "./pages/Otherservicedetail";
function App() {
  return (
    <div className="App">
<BrowserRouter> 
<Hadder/>
      
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/aboutus' element={<Aboutus/>}></Route>
     <Route path='/gallery' element={<Gallery/>}></Route>
     <Route path='/contectus' element={<Contectus/>}></Route>
     <Route path='/gallery' element={<Gallery/>}></Route>
 
     {
            choutelajson.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Details/>}
                  />
                </>
              )
            })
          }

{
            gstjson.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<GstDetails/>}
                  />
                </>
              )
            })
          }
          {
            Rocjson.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<ROCdetail/>}
                  />
                </>
              )
            })
          }
          {
            Tdsjson.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Tdsdetails/>}
                  />
                </>
              )
            })
          }
          {
            OtherServices.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Otherservicedetail/>  }
                  />
                </>
              )
            })
          }
          {
            OtherlegalServices.map((route, index) => {
              console.log("route.slug",route.slug)
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Otherlegaldet/>}
                  />
                </>
              )
            })
          }
          {
            Registration.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Registrationdetail/>}
                  />
                </>
              )
            })
          }
     </Routes>

    </BrowserRouter>

    </div>
  );
}

export default App;
