const Tdsjson = [
    {

        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAR2VB9zfNIfp6ahN2g1ZP3foQAhIYk646hA&usqp=CAU",
        title:"Company Compliance",
        slug:"/company-compliance-services-in-dombivli-in-dombivli",
        
        maincontent:"Ensure your company's compliance with industry regulations and standards. Our comprehensive approach helps you navigate complex legal frameworks, mitigating risks and promoting ethical business practices. Stay ahead of changes in laws and regulations with our expert guidance, ensuring your operations align with the latest compliance requirements. Trust us to safeguard your business integrity, fostering trust with stakeholders and maintaining a solid reputation in the marketplace. Compliance made simple with our dedicated support and tailored solutions.",
        accordentitle:"we work in all fields of business and finance, including auditing, taxation, financial and general management. Engaged in public practice work, others work in the private sector and employed by government bodies. chartered accountant credential qualifies an individual to offer financial consultation and keep financial records.", 
        accorden:[
            {
                sectitle:"LLP compliance ",
                secdecription:" Ensuring LLP compliance is vital for smooth business operations. From annual returns to financial statements, adhering to statutory requirements is crucial. LLPs must file timely reports, maintain updated records, and comply with tax obligations. This not only fosters legal integrity but also enhances stakeholder trust. Regular compliance reviews mitigate risks and promote sustainable growth. Stay ahead by prioritizing LLP compliance, safeguarding your business against penalties and fostering a foundation of reliability in the corporate landscape. ",
            }
           

        ],
        scotitle:"Company Compliance Services In Dombivli | Choutela & Associates",
        scodesc:"Explore comprehensive company compliance services in Dombivli tailored to meet your business needs. Ensure regulatory adherence and legal compliance with our expert assistance. Contact us for reliable and efficient corporate compliance solutions in Dombivli.",
        scoslug:"company compliance services in dombivli in dombivli",
        title1:"Company Compliance Services In Dombivli In Dombivli",
    },
    {
        img:"https://swaritadvisors.com/learning/wp-content/uploads/2018/05/Company-Registration.jpg",
        title:"Company Registration",
        slug:"/company-registration-in-dombivli",
        maincontent:"Ensure a seamless start to your business journey with hassle-free company registration services. Our expert team simplifies the process, guiding you through every step to establish your business legally. From document preparation to submission, we handle it all. With a commitment to efficiency and accuracy, we prioritize your business's legal foundation, allowing you to focus on growth. Trust us for a swift and reliable company registration experience tailored to your needs.",
        accordentitle:"Company registration refers to the process of legally forming a company and obtaining the necessary licenses and permits to operate as a business entity. This process typically includes the following steps:",
        accorden:[
            {
                sectitle:"LLP registration",
                secdecription:" Limited Liability Partnership (LLP) registration is a streamlined process that offers businesses a unique blend of limited liability and operational flexibility. To initiate LLP registration, compile essential documents, such as proof of address and identity, and submit the application online. The Registrar of Companies (RoC) oversees the registration, ensuring compliance with legal requirements. LLP registration enhances business credibility and provides partners with limited liability protection, making it an ideal choice for small and medium enterprises seeking a secure yet adaptable business structure.",
            }
           

        ],
        scotitle:"Company Registration In Dombivli | Choutela & Associates",
        scodesc:"Effortlessly navigate company registration in Dombivli with our expert guidance. Streamline the process, meet compliance standards, and establish your business seamlessly. Unlock the potential of your enterprise with hassle-free registration services tailored to your needs.",
        scoslug:"company registration in dombivli ",
        title1:"Company Registration In Dombivli ",
    },
    
    {
        img:"",
        title:"Roc Filling",
        slug:"/roc-filling-in-dombivli",
        maincontent:"Roc filling refers to a process commonly used in construction and geotechnical engineering. It involves injecting a cement-based grout mixture into rock formations or soil to improve their stability or alter their properties. This technique is particularly useful for strengthening the ground, stabilizing foundations, and preventing erosion. The grout, typically composed of cement, water, and additives, is pumped into drill holes or fissures in the rock or soil, forming a solid mass upon curing. Roc filling is employed in various civil engineering projects, such as building foundations, retaining walls, and infrastructure development, to ensure the durability and safety of structures in challenging geological conditions.",
        accordentitle:" The Registrar of Companies ( ROC ) is an office under the Ministry of Corporate Affairs (MCA), which is the body that deals with the administration of companies and Limited Liability Partnerships (LLPs) in India. At present, Registrar of Companies (ROCs) are operating in all the major states/UT’s.The Registrar of Companies also certifies that LLPs (Limited Liability Partnerships) comply with the legal requirements contained in the Limited Liability Partnership Act, 2008.",
        accorden:[
            {
                sectitle:"LLP Filling",
                secdecription:" Limited Liability Partnership (LLP) filing is a crucial process that ensures legal compliance and smooth business operations. Timely LLP filing guarantees that your business adheres to regulatory requirements, avoiding penalties and legal hassles. Whether it's annual returns, financial statements, or changes in partnership details, meticulous LLP filing is imperative. Trusting this responsibility to experts ensures accuracy and allows you to focus on your core business activities. Stay on top of your legal obligations, safeguard your business, and maintain a seamless operation through diligent LLP filing practices.",
            }
           

        ],
        scotitle:"Roc Filling In Dombivli | Choutela & Associates",
        scodesc:"Effortlessly navigate company registration in Dombivli with our expert guidance. Streamline the process, meet compliance standards, and establish your business seamlessly. Unlock the potential of your enterprise with hassle-free registration services tailored to your needs.",
        scoslug:"roc filling in dombivli",
        title1:"Roc Filling In Dombivli",
    },
]

export default Tdsjson