const Registration = [
   
    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS9CCeI1jYOXk9ztiZTnwlmK7PQoKkO7gYHQ&usqp=CAU",
        title:"Partnership firm registration",
        slug:"/partnership-firm-registration-in-dombivli",
        maincontent:"Partnership firm registration is a crucial step for businesses looking to operate as a partnership. It involves filing the necessary documents with the relevant authorities, defining the terms of the partnership, and obtaining a unique registration. This legal process ensures transparency, legitimacy, and clarity in the business structure.",
        accordentitle:"",
        accorden:[
            {
                sectitle:"For Sole Proprietorship / Individual",
                ul1:"Small scale industries play a vital role in the socio-economic development of India. To promote and encourage these small-scale industries, the government of the country created the MSMED Act. The Act was created to maintain a list of all the industries registered as MSME or SSI. The record aided in providing support and incentives to these firms. Furthermore, SSI helped in employment of people at little capital investments. All these entities should possess certificate of MSME registration, which should be registered with the Central Government’s MSME department under the Act of MSME Development- 2006",
            }
           

        ],
        scotitle:"Partnership Firm Registration in Dombivli | Choutela & Associates",
        scodesc:"Explore seamless Partnership Firm registration services in Dombivli with expert assistance. Our professionals ensure a smooth and efficient process for establishing your partnership business. Register today for a robust foundation in the dynamic business landscape.",
        scoslug:"partnership firm registration in dombivli ",
        title1:"Partnership Firm Registration in Dombivli"
    },
    {
        img:"https://splan.in/wp-content/uploads/2019/01/Article.png",
        title:"Udyog Aadhar Registration",
        slug:"/udyog-aadhar-registration-in-dombivli",
        maincontent:" Udyog Aadhar registration is a simplified process for small and medium-sized enterprises (SMEs) in India. It provides a unique identification number and various benefits, including easier access to credit and government schemes. To register, businesses need to submit basic details online. This initiative aims to promote and support the growth of micro, small, and medium enterprises by simplifying regulatory processes. Udyog Aadhar registration is an essential step for SMEs looking to avail themselves of government incentives and opportunities.",
        accordentitle:"An SSI also called Small Scale Industry Registration is a registration from the Ministry of Micro, Small and Medium Enterprises. When a business registers as SSI it becomes eligible to avail government schemes and subsidies that are exclusive for small businesses",
        accorden:[
            {
                sectitle:"What is SSI/MSME Registration?",
                ul1:"There are two main types of professional tax:",
            }, {
                sectitle:"MSME registration can be done by enterprises of sizes",
                secdecription:"Micro Small Medium Under the MSMED Act, these small businesses get varying incentives such as: 1) Priority sector lending 2)Tax exemptionsd 3)Capital investment Registering for MSME is an entire online process but seeking the help of professionals makes it more easy. Vakilsearch assists you with the online application and gets the required approval from the department.",
            }, {
                sectitle:"New Udyog Aadhaar replaces SSI / MSME Registration",
                secdecription:"Small scale industries play a vital role in the socio-economic development of India. To promote and encourage these small-scale industries, the government of the country created the MSMED Act. The Act was created to maintain a list of all the industries registered as MSME or SSI. The record aided in providing support and incentives to these firms. Furthermore, SSI helped in employment of people at little capital investments. All these entities should possess certificate of MSME registration, which should be registered with the Central Government’s MSME department under the Act of MSME Development- 2006",
            }, {
                sectitle:"What is SSI/MSME Registration?",
                secdecription:"Small scale industries play a vital role in the socio-economic development of India. To promote and encourage these small-scale industries, the government of the country created the MSMED Act. The Act was created to maintain a list of all the industries registered as MSME or SSI. The record aided in providing support and incentives to these firms. Furthermore, SSI helped in employment of people at little capital investments. All these entities should possess certificate of MSME registration, which should be registered with the Central Government’s MSME department under the Act of MSME Development- 2006",
            }, {
                sectitle:"What is SSI/MSME Registration?",
                secdecription:"Small scale industries play a vital role in the socio-economic development of India. To promote and encourage these small-scale industries, the government of the country created the MSMED Act. The Act was created to maintain a list of all the industries registered as MSME or SSI. The record aided in providing support and incentives to these firms. Furthermore, SSI helped in employment of people at little capital investments. All these entities should possess certificate of MSME registration, which should be registered with the Central Government’s MSME department under the Act of MSME Development- 2006",
            }, {
                sectitle:"What is SSI/MSME Registration?",
                secdecription:"Small scale industries play a vital role in the socio-economic development of India. To promote and encourage these small-scale industries, the government of the country created the MSMED Act. The Act was created to maintain a list of all the industries registered as MSME or SSI. The record aided in providing support and incentives to these firms. Furthermore, SSI helped in employment of people at little capital investments. All these entities should possess certificate of MSME registration, which should be registered with the Central Government’s MSME department under the Act of MSME Development- 2006",
            }, {
                sectitle:"What is SSI/MSME Registration?",
                secdecription:"Small scale industries play a vital role in the socio-economic development of India. To promote and encourage these small-scale industries, the government of the country created the MSMED Act. The Act was created to maintain a list of all the industries registered as MSME or SSI. The record aided in providing support and incentives to these firms. Furthermore, SSI helped in employment of people at little capital investments. All these entities should possess certificate of MSME registration, which should be registered with the Central Government’s MSME department under the Act of MSME Development- 2006",
            },
            
           

        ],
        scotitle:"Udyog Aadhar Registration In Dombivli | Choutela & Associates",
        scodesc:"Get hassle-free Udyog Aadhar registration in Dombivli with our expert assistance. Empower your business with the benefits of MSME registration. Quick and reliable Udyog Aadhar application process tailored to your needs",
        scoslug:"udyog aadhar registration in dombivli",
        title1:"Udyog Aadhar Registration In Dombivli",
    },
    {
        img:"https://corpbiz.io/learning/wp-content/uploads/2019/12/Professional-Tax-Registration.jpg",
        title:"P.T. Registration",
        slug:"/professional-tax-registration-in-dombivli",
        maincontent:"Professional tax is a tax imposed by some states in India on people working in certain professions. It is usually a small amount that is deducted from an employee's salary and paid by the employer to the government. The rate and amount of professional tax varies depending on the state and the profession. Examples of professions that may be subject to professional tax include doctors, lawyers, engineers, and chartered accountants.",
        accordentitle:"Professional tax is a tax imposed by some states in India on people working in certain professions. It is usually a small amount that is deducted from an employee's salary and paid by the employer to the government. The rate and amount of professional tax varies depending on the state and the profession. Examples of professions that may be subject to professional tax include doctors, lawyers, engineers, and chartered accountants.",
        accorden:[
            {
                sectitle:"Types of professional tax",
                ul1:"There are two main types of professional tax:",
                ul2:"State-level professional tax: This is a tax imposed by individual states in India on people working within the state. The rate and amount of professional tax varies depending on the state and the profession. Each state has its own legislation and rules for imposing and collecting professional tax.",
                ul3:"Municipal-level professional tax: This is a tax imposed by municipalities or local bodies on people working within their jurisdiction. The rate and amount of professional tax varies depending on the municipality and the profession. Municipal professional tax is usually imposed on professionals such as doctors, engineers, chartered accountants, and lawyers.",
            }, {
                sectitle:"Benefits of professional tax",
                ul1:"Professional tax is generally used to fund state and municipal services and infrastructure, such as road construction, public schools, and healthcare. Some potential benefits of professional tax include: Revenue generation:Professional tax provides a steady stream of revenue for the government, which can be used to fund public services and infrastructure. Encouraging compliance: Professional tax is often seen as a way to encourage compliance with tax laws, as it is typically deducted from an employee's salary by the employer. Supporting local services: Professional tax can be used to fund services and infrastructure that directly benefit the local community, such as schools, hospitals, and parks. Fairness: Professional tax is considered as a fair way of taxing as it is imposed on individuals based on their profession and income level.",
            }, 
            {
                sectitle:"Who Pays Professional Tax?",
                ul1:"Professional tax is typically imposed on individuals who are engaged in certain professions or occupations. The specific professions that are subject to professional tax vary depending on the state or municipality. Some common professions that may be subject to professional tax include: ",
                ul2:"Doctors",
                ul3:"Lawyers",
                ul4:"Engineers",
                ul5:"Chartered accountants",
                ul6:"Architects",
                ul7:"Management consultants ",
                ul8:"IT professionals",
                ul9:"Teachers",
                ul10:"Government employees",
                ul11:"Businessmen",
            }, 
           

        ],
        scotitle:"Professional Tax Registration In Dombivli | Choutela & Associates",
        scodesc:"Streamline your business in Dombivli with hassle-free Professional Tax Registration services. Our experts ensure seamless compliance, guiding you through the process for a stress-free experience. Get registered efficiently and focus on growing your business.",
        scoslug:"professional-tax-registration-in-dombivli",
        title1:"Professional Tax Registration In Dombivli "
    },
    {
        img:"https://corpbiz.io/learning/wp-content/uploads/2019/12/Professional-Tax-Registration.jpg",
        title:"P.T. Return",
        slug:"/professional-tax-return-in-dombivli",
        maincontent:"Professional Tax (P.T.) Return is a crucial document in India, required by individuals employed in certain professions. It serves as a record of the professional tax paid by employees and is vital for compliance with state regulations. The P.T. Return includes details such as the individual's name, profession, income, and the amount of professional tax deducted by the employer. Filing accurate and timely P.T. Returns is essential to avoid penalties and legal issues. Different states have varying rules and deadlines for P.T. Return submission, emphasizing the importance of staying informed and ensuring compliance to fulfill one's tax obligations professionally.",
        accorden:[
            {
                sectitle:"Advantage",
                ul1:"Professional Tax Registration offers numerous advantages, streamlining compliance for businesses. It ensures legal adherence, preventing penalties and legal repercussions. The process fosters transparency, aiding in efficient financial management. Additionally, registration grants access to government benefits and exemptions, contributing to overall business sustainability and growth.",
               

            },],
        scotitle:"Professional Tax Registration In Dombivli | Choutela & Associates",
        scodesc:"Streamline your business in Dombivli with hassle-free Professional Tax Registration services. Our experts ensure seamless compliance, guiding you through the process for a stress-free experience. Get registered efficiently and focus on growing your business.",
        scoslug:"professional tax return in dombivli",
        title1:"Professional Tax Registration In Dombivli"
    },
    {
        img:"https://i0.wp.com/www.legitfilings.com/wp-content/uploads/2019/07/pf-return.png?fit=600%2C593",
        title:"P.F. Registration",
        slug:"/provident-fund-registration-in-dombivli",
        maincontent:"Provident Fund (PF) Registration are essential financial documents that showcase an individual's or employee's contribution to their retirement savings. It serves as a comprehensive record, detailing both the employee and employer contributions over time. Filing accurate PF Returns is crucial for financial transparency and ensures compliance with regulatory requirements. It reflects the commitment to long-term financial planning, offering a secure nest egg for employees post-retirement. Timely and precise PF Returns submission fosters financial stability and peace of mind.",
        accordentitle:"Professional tax is a tax imposed by some states in India on people working in certain professions. It is usually a small amount that is deducted from an employee's salary and paid by the employer to the government. The rate and amount of professional tax varies depending on the state and the profession. Examples of professions that may be subject to professional tax include doctors, lawyers, engineers, and chartered accountants.",
        accorden:[
            {
                sectitle:"Eligibility criteria for an employee",
                ul1:"Any salaried employee who is a resident of India is liable to be a member of the employee provident fund scheme.",
                ul2:"The employee is liable for this scheme right from the first day of his/her joining to any job.",
                ul3:"Once the employee becomes a member, he/she is responsible for provident funds benefits along with the insurance and pension benefits.",
                ul4:"It is mandatory for employees having a salary of Rs. 15,000 or more to be a member of this scheme although the employee can voluntarily apply for it at any wage.",
                ul5:"The employee contributes a minimum 12% of salary (can voluntarily contribute more).",

            },
            {
                sectitle:"  Timeline to get EPF registration in India",
                ul1:"EFP registration (Provident Fund registration) has to be done within One month from the date of hiring 20 employees. Any delay may result in a penalty.",
                ul2:"The application for PF Registration is made online with the Digital Signature of one director or the Authorized Signatory of the company.",
               

            },
        
            {
                sectitle:"List of required documents for PF ",
                ul1:"Following is the complete list of documents required for PF :",
                ul2:"Digital Signature of Proprietor/Partner/Director",
               ul3:"Aadhar Card of Proprietor/Partner/Director",
               ul4:"PAN Card of Proprietor/Partner/Director",
               ul5:"Cancelled Cheque/Bank Statement of Entity",
            ul6:"PAN Card of entity",
            ul7:"Electricity Bill of the Registered Office (not older than 2 months)",
            ul8:"Shop and establishment Certificate/GST Certificate/ License issued by the government for factory",
            }
           

        ],
        scotitle:"Provident Fund Registration In Dombivli| Choutela & Associates",
        scodesc:"Streamline your business in Dombivli with hassle-free Professional Tax Registration services. Our expert team ensures a swift and compliant registration process, allowing you to focus on your core operations. Get started today for a seamless and stress-free experience.",
        scoslug:"provident fund registration in dombivli",
        title1:"Provident Fund Registration In Dombivli"
    },
    {
        img:"https://i0.wp.com/www.legitfilings.com/wp-content/uploads/2019/07/pf-return.png?fit=600%2C593",
        title:"P.F. Return Filling",
        slug:"/Provident-Fund-return-filling-registration-in-dombivli",
        maincontent:"Preparing and filing your Income Tax Return (ITR) is crucial for financial compliance. The process involves reporting your income, deductions, and other financial details to the Income Tax Department. It's essential to file your returns accurately and on time to avoid penalties. Utilize the online portal or seek professional assistance for a seamless experience. Stay informed about deadlines and changes in tax laws to ensure a smooth filing process and financial well-being.",
        accordentitle:"An SSI also called Small Scale Industry Registration is a registration from the Ministry of Micro, Small and Medium Enterprises. When a business registers as SSI it becomes eligible to avail government schemes and subsidies that are exclusive for small businesses.",
        accorden:[
            {
                sectitle:"Eligibility criteria for an employee",
                ul1:"Any salaried employee who is a resident of India is liable to be a member of the employee provident fund scheme.",
                ul2:"The employee is liable for this scheme right from the first day of his/her joining to any job.",
               ul3:"Once the employee becomes a member, he/she is responsible for provident funds benefits along with the insurance and pension benefits.",
               ul4:"It is mandatory for employees having a salary of Rs. 15,000 or more to be a member of this scheme although the employee can voluntarily apply for it at any wage.",
               ul5:"The employee contributes a minimum 12% of salary (can voluntarily contribute more).",
           
            },
            {
                sectitle:"Timeline to get EPF registration in India",
                ul1:"EFP registration (Provident Fund registration) has to be done within One month from the date of hiring 20 employees. Any delay may result in a penalty.",
                ul2:"The application for PF Registration is made online with the Digital Signature of one director or the Authorized Signatory of the company.",
             
           
            },
            {
                sectitle:"List of required documents for PF ",
                ul1:"Following is the complete list of documents required for PF :",
                ul2:"Digital Signature of Proprietor/Partner/Director ",
               ul3:"Aadhar Card of Proprietor/Partner/Director",
               ul4:"PAN Card of Proprietor/Partner/Director",
               ul5:"Cancelled Cheque/Bank Statement of Entity",
            ul6:"PAN Card of entity",
            ul7:"Electricity Bill of the Registered Office (not older than 2 months)",
            ul8:"Shop and establishment Certificate/GST Certificate/ License issued by the government for factory",
            }
           

        ],
        scotitle:"Provident Fund Return Filling Registration In Dombivli | Choutela & Associates",
        scodesc:"Efficient and reliable Provident Fund return filing registration services in Dombivli. Expert assistance for seamless tax compliance, ensuring accuracy and timely submissions. Trust us for hassle-free tax filing solutions tailored to meet your business needs.",
        scoslug:"Provident Fund return filling registration in dombivli",
        title1:"Provident Fund Return Filling Registration In Dombivli"
    },
    {
        img:"https://media.lendingkart.com/wp-content/uploads/2021/03/ESIC-Registration.jpg",
        title:"E.S.I.C Registration",
        slug:"/employees-state-insurance-corporation-registration-in-dombivli",
        maincontent:" ESIC registration is crucial for businesses to ensure employee welfare. The Employees' State Insurance Corporation (ESIC) provides medical and financial benefits to employees in case of illness, maternity, or disablement. By registering with ESIC, employers contribute to a social security scheme that safeguards their workforce. This not only fulfills legal obligations but also fosters a supportive work environment. Businesses benefit from enhanced employee well-being, contributing to a healthier and more productive workforce. Ensure compliance and prioritize your employees' welfare through timely ESIC registration.",
        accordentitle:"The general rule to be eligible for ESI (Employees' State Insurance) registration is to have 10 or more workers. But in some regions, ESI is applicable only if there are more than 20 employees.",
        accorden:[
            {
                sectitle:"Due Dates For ESI",
                ul1:"Payment: 15th of every month",
                ul2:"Returns: May 11th and November 11th "
            },
            {
                sectitle:"Eligibility for ESI Registration in India",
                ul1:"Other eligibility criteria that need to be fulfilled are:",
                ul2:"1)Any employee whose gross salary is up to Rs. 21,000 per month can avail this with the help of the employer. ",
                ul3:"2)Establishment Registered with EPFO.",
                ul4:"The contribution to ESI is 6.5% of the gross salary. It is divided as: 1)4.75% by the employer",
                ul5:"2)1.75% by the employee",
                ul6:"For industrial units, where there are chances of occurrence of injury or health issues at the business premise, all employees with salary less than Rs.21,000 compulsorily need to have ESI.",
            },
            {
                sectitle:"Benefits of ESI",
                ul1:"From the very first day of employment, registered ESI members and their families are entitled to enjoy the benefits of complete medical care and insurance.",
                ul2:"Pregnant women are entitled to maternity benefits that are payable upto twenty six weeks. This period can be extended by 30 days on medical advice. To qualify for maternity benefits, employers are required to contribute their wages for 70 days in the preceding two contributions periods.",
                ul3:"Disabled employees can get 90% of their monthly salaries as disablement benefits.",
                ul4:"Absence from work during illness can be taken for a maximum of 91 days per year along with 70% of the monthly wages.",
                ul5:"In the unfortunate event of the demise of an employee during the employment, the dependents of the deceased will receive 90% of his/her monthly salary.",
                ul6:"The family of the deceased employee is entitled to an additional amount of Rs.10,000 towards funeral expenses.",
                ul7:"In the case of confinement of an insured woman or wife of an employee occurring in a place with no medical facilities under the ESI scheme, confinement expenses can be availed.",
                h61:"Medical Aid",
                h62:"Maternity Aid",
                h63:"Disablement Benefits",
                h64:"Sickness Benefits",
                h65:"Dependent Benefits",
                h66:"Funeral Expenses",
                h67:"Confinement Expenses",
            },
            {
                sectitle:"Documents required for PF Registration",
                ul1:"PAN card of establishment",
                ul2:"Certificate of incorporation",
                ul3:"Cross cancelled cheque of establishment",
                ul4:"Rent agreement",
                ul5:"Water",
                ul6:"Electricity",
                ul7:"Telephone bill",
                ul8:"Specimen signature of directors and authorized signatories",
                ul9:"In case of voluntary registration, consent of the majority of employees",
                ul10:"First sale bill",
                ul11:"First purchase bill of raw material and machinery",
                ul12:"GST Registration Certificate",
                ul13:"Bankers details",
                ul14:"Record of a monthly employee strength",
            }
           
           

        ],
        scotitle:"Employees State Insurance Corporation Registration In Dombivli | Choutela & Associates",
        scodesc:"Ensure compliance with Employees State Insurance Corporation (ESIC) registration in Dombivli. Access essential information and streamline the process for securing employee benefits. Expert guidance for seamless ESIC registration in Dombivli, promoting workforce well-being and statutory adherence.",
        scoslug:"employees state insurance corporation registration in dombivli",
        title1:"Employees State Insurance Corporation Registration In Dombivli ",
    },
    {
        img:"https://compliancecalendar.s3.ap-south-1.amazonaws.com/assets/ogimage/esi-epf-returns-filing.jpg",
        title:"E.S.I.C Return Filling",
        slug:"/employees-state-insurance-corporation-return-filling-in-dombivli",
        maincontent:" ESIC (Employee's State Insurance Corporation) return filing is a crucial process for businesses to comply with social security regulations. Employers must submit accurate details of employees, wages, and contributions to ensure seamless benefits for workers. Timely ESIC return filing reflects responsible corporate citizenship, avoiding penalties and legal issues. Streamlining this process enhances organizational efficiency and employee welfare. Staying updated on ESIC guidelines is vital for employers, fostering a conducive work environment and reinforcing their commitment to social responsibility.",
        accordentitle:"Employees' State Insurance (ESI) is self-financing social security and health insurance scheme for the Indian Workers, which is an autonomous corporation governed by Ministry of Labour and Employment. This Fund of ESI is managed by Employee State Insurance Corporation (ESIC) and its rules regulations. All entities registered under ESI registration must file ESI returns which is due half yearly.       ",
        accorden:[
            {
                sectitle:"Applicability of ESI",
                ul1:"The ESIC is applicable on all the establishments having 10 or more workers and is beneficial to all the employees earning Rs.15, 000/- or less per month as wages, employer must contribute 4.75 percent and employee must contributes 1.75 percent towards ESI.",
               
            },
            {
                sectitle:"Benefits of ESIC",
                ul1:"Medical Benefits One of the main objectives of taking this form of registration is medical benefits to employees and workman. If any sickness or other contingency is experienced by workforce, then the same can be availed immediately by an affected worker.",
                ul2:"Social Security The ESI registration scheme was brought out as a social security provided by the government. Through this scheme, social security features such as maternity benefits, sickness allowance and other related benefits are provided.",
               ul3:"Easy Processing Benefits under this system can be availed easily at any hospital or health care centre. All payments which are used under this system can be compensated.",
               ul4:"Benefits related to Sickness Through the ESIC registration, all employees would be offered different form of sickness benefits. These benefits are offered at a rate of 70% of the salary of the employer. This amount would be considered and provided if the sickness extends over three consecutive months or 90 days.",
               ul5:"Maternity Benefits Every organisation taking up this scheme would have to provide maternity benefits such as maternity leave during pregnancy.",
               ul6:"Disability Benefits Disability Benefits are also covered as a part of this scheme. In an unforeseen event in a permanent disability or the death of the employee, this can be claimed by the dependants of the employee."
            },
            {
                sectitle:"How to file ESIC return?",
                ul1:"Log in Employer Portal using 17 digit Employer digit code. This code is provided to the company when registering under the ESI rules. When the 17 digit registration number is received by the company, the ESI filing process can be easily carried out.",
                ul2:"Go to monthly contribution section. Before considering this, the employer or factory must make sure that all the information related to the employees are updated before filing the returns.",
               ul3:"All the details of the employer contribution will be displayed.",
               ul4:"Verify the details. After this enter bank details and go to submit the monthly employee return.",
               ul5:"If there are any short payments in respect of employees then complete the dues.",
               ul6:"Do Self Certification under monthly contribution section, then check mark the declaration and submit the return."
            },
            {
                sectitle:"Documents Required to File ESI return",
                ul1:"The following documents are required to file ESI return",
                ul2:"Registration Certificate or the respective license which is obtained under the Shops and Establishments Act.",
               ul3:"If this is a company registered under the provisions of the Companies Act, 2013 then the certificate of incorporation must be provided",
               ul4:"The Memorandum of Association and Articles of Association also must be provided along with the other documents",
               ul5:"If the entity is a partnership company, then the copy of the respective trust deed must be provided.",
               ul6:"If the entity is a factory, then the certificate of commencement of work in the factory must be provided.",
               ul7:"Information on the employees of the establishment or the factory",
               ul8:"Information on the monthly contribution of the employees of the factory or the entity",
               ul9:"A detailed list of all the directors, shareholders and partners of the company",
               ul10:"Permanent Account Number (PAN) of the Establishment or the Entity",
                ul11:"Respective Bank Statement of the Organisation",
                ul12:"Register of Form- 6",
                ul13:"Wages Registry",
                ul14:"Address of the premises and other information related to the premises",
                ul15:"Inspection Book and Certificates",
                ul16:"Attendance registers of the Company",
            },
            {
                sectitle:"Reasons to Register a ESI",
                ul1:"ESI contributions must be made by the employer for all employees having a salary of less than Rs.15,000 per month. The employer must contribute 4.75% and employee must contribute 1.75% of the wages for ESI.",
                ul2:"Employee's State Insurance Corporation(ESI) is a self-ESI provides employees registered the scheme with a host of medical and sickness benefits. Employees registered under the scheme can avail medical facilities and are also entitled for sick pay benefits.",
               ul3:"Organizations under ESI coverage must file annual return showing the changes if any during the preceding year. Return of contributions enclosing copy of all ESI contributions paid must be submitted once every six months.",
               ul4:"ESI Registration for Employers having more than 10 employees. The Employer must contribute 4.75% of the Employees wages towards ESI. Once, registered the organization is allotted a 17 digit unique identification code.",
              h61:"ESI Contribution",
              h62:"ESI Benefits",
              h63:"Continued Compliance",
              h64:"ESI Registration",

            },
           

        ],
        scotitle:"Employees State Insurance Corporation Return Filling In Dombivli | Choutela & Associates",
        scodesc:"Ensure compliance with Employees State Insurance Corporation (ESIC) registration in Dombivli. Access essential information and streamline the process for securing employee benefits. Expert guidance for seamless ESIC registration in Dombivli, promoting workforce well-being and statutory adherence.",
        scoslug:"employees state insurance corporation return filling in dombivli",
        title1:"Employees State Insurance Corporation Return Filling In Dombivli",
    },
    {
        img:"https://compliancecalendar.s3.ap-south-1.amazonaws.com/assets/ogimage/fssai-registration.jpeg",
        title:"FSSAI Registration",
        slug:"/fssai-registration-in-dombivli",
        maincontent:" FSSAI registration is crucial for ensuring food safety and compliance with regulatory standards in India. Obtaining FSSAI certification validates the quality and authenticity of your food products, instilling consumer trust. This registration process involves submitting necessary documents and adhering to specific guidelines. By acquiring FSSAI registration, businesses demonstrate their commitment to food safety, fostering a positive reputation. It is a legal requirement that promotes responsible food handling and contributes to overall public health. Ensure your business is FSSAI registered to thrive in the competitive food industry.",
        accordentitle:"Food License Registration certificate is required to run a food product business. You have to register under Food Safety and Standards Authority of India (FSSAI) to manufacture, distribute and transport food products.",
        accorden:[
            {
                sectitle:"Main Functions Of FSSAI",
                ul1:"FSSAI's main functions include:",
                ul2:"Formulating and enforcing regulations for food safety and hygiene.",
               ul3:"Granting licenses and registrations to food business operators (FBOs).",
               ul4:"Conducting inspections and audits of food businesses to ensure compliance with regulations.",
               ul5:"Providing guidance and training to FBOs on food safety and hygiene practices., then the copy of the respective trust deed must be provided.",
               ul6:"Collaborating with state and local governments, as well as other stakeholders, to improve food safety and hygiene in India.",
              
            },
            {
                sectitle:"Benefits of FSSAI registration",
                ul1:"Benefits Of FSSAI Registration There are several benefits to obtaining FSSAI registration or license for a food business operator (FBO) in India:",
                ul2:"Legal Compliance: FSSAI registration or license is mandatory as per the Food Safety and Standards Act, 2006, and failure to obtain it can result in penalties and fines.",
               ul3:"Consumer Trust: FSSAI registration or license serves as an assurance to consumers that the food products being offered by the FBO are safe for consumption and of good quality.",
               ul4:"Improved Business Image: FSSAI registration or license can enhance the reputation of the FBO and increase consumer confidence in their products.",
               ul5:"Increased Market Access: FSSAI registration or license is often a prerequisite for participating in government procurement and tenders, as well as for exporting food products",
               ul6:"Easier Access to Financing: FSSAI registration or license can make it easier for FBOs to obtain loans or other forms of financing, as it serves as evidence of their compliance with legal and regulatory requirements.",
              
            },
            {
                sectitle:"Procedure for FSSAI registration",
                ul1:"Procedure For FSSAI Registration The procedure for obtaining FSSAI registration or license for a food business operator (FBO) in India typically involves the following steps:",
                ul2:"Determine the type of registration or license required: FSSAI registration or license is required based on the type and scale of the food business. There are different categories of registration or license, such as Basic registration, State license and Central license.",
               ul3:"Submit the application: Once you have determined the type of registration or license required, you can submit the application online or offline along with the necessary documents, such as proof of business address, details of food products and processing methods, and declarations regarding food safety and hygiene practices.",
               ul4:"Inspection and Verification: After submitting the application, an FSSAI official will conduct an inspection of the food business premises to verify the information provided in the application.",
               ul5:"Payment of fees: After the inspection, the FBO will have to pay the relevant fees for the registration or license.",
               ul6:"Obtain the registration or license: Once the inspection and verification are complete and the fees have been paid, the FBO will be issued with the FSSAI registration or license certificate. Please note that the FSSAI registration or license is valid for a certain period of time and must be renewed before its expiry date.",
              
            },
           

        ],
        scotitle:"FSSAI Registration In Dombivli | Choutela & Associates",
        scodesc:"Get hassle-free FSSAI registration in Dombivli with our expert assistance. Ensure compliance with food safety regulations and unlock opportunities for your food business. Fast and reliable service tailored to your needs. Contact us now for a seamless FSSAI registration process in Dombivli.",
        scoslug:"fssai registration in dombivli",
        title1:"FSSAI Registration In Dombivli",
    },
    {
        img:"https://5.imimg.com/data5/SELLER/Default/2023/4/303447453/LV/PM/XJ/9470209/import-export-code-registration-services.png",
        title:"Import Export CODE (IEC)",
        slug:"/import-export-code-registration-in-dombivli",
        maincontent:"Obtaining an Import Export Code (IEC) in India, a 10-digit identification number issued by the DGFT, is crucial for businesses engaged in international trade. This mandatory code streamlines customs clearance, fostering smooth import and export processes. It opens avenues to global markets, enhancing competitiveness, and ensuring compliance with international trade regulations. The IEC serves as a fundamental credential, empowering businesses to actively participate and thrive in cross-border trade activities.",
        accordentitle:"Import and Export code is a 10 digit unique number issued by the Directorate General of Foreign Trade (DGFT) to a business entity for import and export in India. The Import and Export Code helps the business grow in the global market. To clear customs, the trader must ensure that the importing entity has IE code and GST registration before initiating import of goods. If an importer does not have both IE code and GST Registration, the goods will be stuck at the port and will start incurring demurrage charges or could be destroyed. Once issued the IE Code is valid throughout the existence of the entity and there is no need to renew the code.",
        accorden:[
            {
                sectitle:"Importance of Import Export Code",
                ul1:"As the IE Code is a requirement for the import and the export business they allow the products to reach the international market. IE code makes the entry of the Indian business in the international smoother and opens doors for growth and expansion.",
                ul2:"The process to find the IE Code is completely online and hassle-free with little document submission.",
               ul3:"To obtain IE Code in India very few documents are required. Here is the list of mandatory documents that are required for obtaining the Import Export Code:- PAN of the applicant, Canceled Cheque, Passport size photo of the applicant, Address proof of the applicant",
               ul4:"IE Code is a lifetime registration that is valid as long as the business is in existence. Hence, there are no issues with updating, filing, and renewing Import Export Code registration. The IE registration is valid till the business is in existence or the registration is not revoked or surrendered.",
             ul5:"The most basic requirement for the Import-Export code is that you need to provide authentic information. Without providing proper information IE code cannot be obtained. This criterion makes transportation of illegal goods impossible.",
             ul6:"IE code has huge benefits for both importers and exporters. The registered business entities will be able to avail the benefits in form of the subsidies from the Customs, Export Promotion Council or other authorities. With LUT filing under GST, the exporters can make exports without paying the taxes. In case the exports are made with payment of tax the exporter can claim the refunds of the paid tax amount.",
            ul7:"Unlike other tax registration the person carrying import or export does not require to fulfill any specific compliance requirement such as the annual filing or the return filings.",
               h61:"International Market Unlocks",
              h62:"Online Registration:",
              h63:"Less Document Requirement:",
              h64:"Lifetime Validity:",
              h65:"Reduces Illegal Goods Transportation:",
              h66:"Benefits Of The Schemes:",
              h67:"Compliance:"

            },
            {
                sectitle:"Proprietorship",
                ul1:"Digital Photograph (3x3cms) of the Proprietor.",
                ul2:"Copy of PAN card of the Proprietor.",
               ul3:"Copy of Passport (first & last page)/Voter’s I-Card/ Driving Licence/UID (Aadhar card) (any one of these).",
               ul4:"Sale deed in case business premise is self-owned; or Rental/Lease Agreement, in case office is rented/ leased; or latest electricity /telephone bill.",
               ul5:"Bank Certificate as per ANF 2A(I)/ Cancelled Cheque bearing preprinted name of applicant and A/C No.",
              
            },
            {
                sectitle:"Society or Trust",
                ul1:"Digital Photograph (3x3cms) of the signatory applicant/Secretary or Chief Executive.",
                ul2:"Copy of PAN card of the applicant entity.",
               ul3:"Copy of Passport (first & last page)/Voter’s I-Card /UID (Aadhar Card) /Driving Licence/ PAN (any one of these) of the Secretary or Chief Executive/ Managing Trustee signing the application.",
               ul4:"Sale deed in case business premise is self-owned; or Rental/Lease Agreement, in case office is rented/ leased; or latest electricity /telephone bill.",
               ul5:"Registration Certificate of the Society / Copy of the Trust Deed",
              ul6:"Bank Certificate as per ANF 2A(I)/Cancelled Cheque bearing preprinted name of the Registered Society or Trust and A/C No.",
            },
           

        ],
        scotitle:"Import Export CODE Registration In Dombivli | Choutela & Associates",
        scodesc:"Effortlessly navigate the import-export landscape with streamlined CODE registration services in Dombivli. Ensure compliance and accelerate your global trade ambitions. Trust us for swift and reliable Import Export CODE registration solutions tailored to your business needs.",
        scoslug:"import export code registration In dombivli",
        title1:"Import Export CODE Registration In Dombivli"
    },

]

export default Registration